<template>
  <div id="AnalysisOfSourceAndUsageDirection">
    <div class="tableBox">
      <div class="content">
        <div class="content_title">现金流入流出TOP3</div>
        <div class="row">
          <ABar style="width: 50%" :seriesIndex="1" ref="ABar2"></ABar>
          <div class="right">
            <div class="right_text">
              <div v-for="(item, index) in income_subjects" :key="index">
                <div class="text_title" style="font-weight: 500">
                  {{ item.title }}
                </div>

                <div class="pl-3" v-if="index == 'income_subjects'">
                  <div
                    class="text_item"
                    v-for="(body, i) in item.body"
                    :key="i"
                  >
                    {{ body.name }}流入金额为{{
                      thousandBitSeparator(body.value)
                    }}万元,占比为{{ body.rate }};
                  </div>
                </div>
                <div class="pl-3" v-else>
                  <div
                    class="text_item"
                    v-for="(body, i) in item.body"
                    :key="i"
                  >
                    {{ body.name }}流出金额为{{
                      thousandBitSeparator(body.value)
                    }}万元,占比为{{ body.rate }};
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content_box">
      <div class="content" style="padding-left: 20px">
        <div class="row align-items-center justify-content-between">
          <div
            style="
              font-size: 18px;
              color: #333333;
              line-height: 26px;
              font-weight: 550;
            "
          >
            现金流入流出情况
          </div>
        </div>
        <WaterfallBar ref="ABar1"></WaterfallBar>
      </div>
      <div class="content" style="padding-right: 20px">
        <div class="row align-items-center justify-content-between">
          <div
            style="
              font-size: 18px;
              color: #333333;
              line-height: 26px;
              font-weight: 550;
            "
          >
            当期现金流指标情况
          </div>
          <div class="pr-3">
            <el-button-group>
              <el-button
                @click="toggleTypes(false)"
                size="mini"
                style="font-size: 14px; width: 100px"
                :class="!shows && 'active'"
                >行业对比</el-button
              >
              <el-button
                @click="toggleTypes(true)"
                size="mini"
                style="font-size: 14px; width: 100px"
                :class="shows && 'active'"
                >历史趋势</el-button
              >
            </el-button-group>
          </div>
        </div>
        <c-table
          class="mt-3"
          v-show="!shows"
          :tableData="tableData"
          style="width: 98%"
        ></c-table>
        <div class="mt-3" v-show="shows" style="width: 100%; height: 350px">
          <div ref="linChartCunt" class="linChartCunt"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as sesstion from "@/store/localStorage";

export default {
  data() {
    return {
      desc_arr1: [],
      income_subjects: {},
      tableData: [],
      shows: false,
      company_type: sesstion.getLocalData("company").company_type,
      dataList: {},
    };
  },
  created() {
    this.$VM.AnalysisOfSourceAndUsageDirection = this;
  },
  components: {
    CTable: require("../CashFlowCapacityAnalysis/Ctables.vue").default,
    WaterfallBar: require("./WaterfallBar").default,
    ABar: require("./ABar").default,
  },
  watch: {},
  mounted() {},
  methods: {
    toggleTypes(type) {
      this.shows = type;
      if (type == true) {
        setTimeout(() => {
          this.getLinCharts();
        }, 500);
      }
    },

    getLinCharts() {
      const dataTime = this.dataList.years;
      const data1 = this.dataList["经营活动现金流量流入流出比"];
      const data2 = this.dataList["现金流动负债比(%)"];
      const data3 = this.dataList["盈利现金倍数"];
      const data4 = this.dataList["资产现金回收率(%)"];
      console.log(data2, data3);
      var option = {
        color: ["#529EE2", "#F7882B", "#FAD33F", "#3EBB9F"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            "经营活动现金流量流入流出比",
            "现金流动负债比(%)",
            "盈利现金倍数",
            "资产现金回收率(%)",
          ],
          icon: "circle",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dataTime,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "经营活动现金流量流入流出比",
            type: "line",
            data: data1,
          },
          {
            name: "现金流动负债比(%)",
            type: "line",
            data: data2,
          },
          {
            name: "盈利现金倍数",
            type: "line",
            data: data3,
          },
          {
            name: "资产现金回收率(%)",
            type: "line",
            data: data4,
          },
        ],
      };
      this.$echarts.init(this.$refs.linChartCunt).setOption(option);
    },
    thousandBitSeparator: function(cellValue) {
      let value = cellValue / 10000;
      // console.log(value);
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.active {
  background: #3a85fd;
  color: #fff;
  border: 1px solid #3a85fd;
}

#AnalysisOfSourceAndUsageDirection {
  background: #fff;
  .tableBox {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    .content {
      width: 100%;
      color: #666666;
      font-size: 18px;

      .content_title {
        font-size: 16px;
        color: #333333;
        font-weight: 550;
      }

      .left {
        margin-top: 10%;
        padding: 20px;
        font-size: 16px;
        color: #333333;

        > div {
          margin-bottom: 10px;
        }
      }

      .right {
        width: 50%;
        margin-top: 30px;
        .right_text {
          width: 100%;
          padding: 15px;
          background: rgb(241, 245, 255);
        }
        .text_title {
          margin-bottom: 5px;
          margin-top: 5px;
          font-size: 16px;
          color: #333333;
        }
        .text_item {
          // padding-left: 10px;
          font-size: 16px;
          color: #333333;
        }
      }
    }
  }
  .content_box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .content {
      width: 49%;
      padding-bottom: 10px;
      min-height: 350px;
      color: #666666;
      font-size: 18px;

      .content_title {
        font-size: 16px;
        color: #333333;
        line-height: 26px;
        font-weight: 550;
      }

      > .unit {
        position: relative;
        z-index: 1;
        font-size: 14px;
        color: #999999;
        float: right;
        margin-top: 56px;
        margin-right: 20px;
      }

      .left {
        margin-top: 10%;
        padding: 20px;
        font-size: 16px;
        color: #333333;

        > div {
          margin-bottom: 10px;
        }
      }

      > .right {
        width: 100%;
        margin-top: 20px;

        > div {
          margin-bottom: 10px;

          .content_item {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .linChartCunt {
    width: 100% !important;
    height: 350px !important;
  }
}
</style>
