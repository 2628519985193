<template>
  <div class="echarts">
    <div
      ref="echartsBig"
      :style="this.year[2] == undefined ? 'margin-left:200px' : ''"
      class="adds"
    ></div>

    <div
      v-show="this.year[2] !== undefined"
      ref="echartsBigTwo"
      style="width: 30%; height: 400px"
    ></div>
    <div class="echartsLengen">
      <div class="box">
        <div class="color1"></div>
        <div>总负债</div>
      </div>
      <div class="box">
        <div class="color2"></div>
        <div>所有者权益</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    year: Array,
  },
  data() {
    return {
      arr,
      arr1,
      arr2,
    };
  },
  watch: {
    data() {
      this.echartsBig();
      this.echartsBigTwo();
    },
  },
  mounted() {
    this.echartsBig();
    this.echartsBigTwo();
  },
  computed: {
    s: function (index) {
      return 2;
    },
  },
  methods: {
    thousandBitSeparator: function (num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
    echartsBig() {
      // 第一个饼图数据
      let one1 = Number(this.data[0].value[0]); //总负债
      let one2 = Number(this.data[3].value[0]); //权益
      // 第二个饼图数据
      let two1 = Number(this.data[0].value[1]); //总负债
      let two2 = Number(this.data[3].value[1]); //权益
      // 第三个饼图数据
      let three1 = Number(this.data[0].value[2]); //总负债
      let three2 = Number(this.data[3].value[2]); //权益
      // 相加总数
      let textOne = one1 + one2;
      let textTwo = two1 + two2;
      let textThree = three1 + three2;
      if (textOne > textTwo && textOne > textThree) {
        this.arr = ["35%", "65%"];
      }
      if (textTwo > textOne && textTwo > textThree) {
        this.arr1 = ["35%", "65%"];
      }
      if (textOne > textTwo && textOne < textThree) {
        this.arr = ["30%", "60%"];
      }
      if (textOne < textTwo && textOne > textThree) {
        this.arr = ["30%", "60%"];
      }
      if (textTwo < textOne && textTwo > textThree) {
        this.arr1 = ["30%", "60%"];
      }
      if (textTwo > textOne && textTwo < textThree) {
        this.arr1 = ["30%", "60%"];
      }
      if (textOne < textTwo && textOne < textThree) {
        this.arr = ["25%", "55%"];
      }
      if (textTwo < textOne && textTwo < textThree) {
        this.arr1 = ["25%", "55%"];
      }
      if (textOne == textTwo) {
        this.arr = ["25%", "55%"];
        this.arr1 = ["25%", "55%"];
      }

      function numFormat(num) {
        var res = num.toString().replace(/\d+/, function (n) {
          // 先提取整数部分
          return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
            return $1 + ",";
          });
        });
        return res;
      }
      var optionOne = {
        color: ["#F7882B", "#529EE2"],
        title: [
          {
            text: numFormat(textOne.toFixed(2))+'（万元）',
            left: "25%",
            top: "87%",
            textAlign: "center",
            textStyle: {
              fontSize: "28px",
              color: "#3A85FD",
            },
          },
          {
            text: numFormat(textTwo.toFixed(2))+'（万元）',
            left: "78%",
            top: "87%",
            textAlign: "center",
            textStyle: {
              fontSize: "28px",
              color: "#3A85FD",
            },
          },
          {
            text: this.year[0] + "年负债和所有者权益总计",
            left: "25%",
            top: "81%",
            textAlign: "center",
            textStyle: {
              fontSize: "18px",
              color: "#333",
              fontWeight: 500,
            },
          },
          {
            text: this.year[1] + "年负债和所有者权益总计",
            left: "78%",
            top: "81%",
            textAlign: "center",
            textStyle: {
              fontSize: "18px",
              color: "#333",
              fontWeight: 500,
            },
          },
        ],

        series: [
          {
            type: "pie",
            radius: this.arr,
            center: ["26%", "45%"],
            // itemStyle: {
            //   borderRadius: 3,
            //   borderColor: "#fff",
            //   borderWidth: 2,
            // },
            // label: {
            //   fontSize: 12,
            //   position: "inner", //center表示文本显示位置为内部
            //   formatter:"{d}%"
            //   },
            labelLine: {
              //引导线设置
              normal: {
                show: false, //引导线不显示
              },
            },
            data: [this.data[0].value[0], this.data[3].value[0]],
          },
          {
            type: "pie",
            radius: this.arr1,
            center: ["78%", "45%"],
            // itemStyle: {
            //   borderRadius: 3,
            //   borderColor: "#fff",
            //   borderWidth: 2,
            // },
            // label: {
            //   fontSize: 12,
            //   position: "inner", //center表示文本显示位置为内部
            //   formatter:"{d}%"
            //   },
            labelLine: {
              //引导线设置
              normal: {
                show: false, //引导线不显示
              },
            },
            data: [this.data[0].value[1], this.data[3].value[1]],
          },
        ],
      };
      this.$echarts.init(this.$refs.echartsBig).setOption(optionOne);
    },
    echartsBigTwo() {
      // 第一个饼图数据
      let one1 = Number(this.data[0].value[0]); //总负债
      let one2 = Number(this.data[3].value[0]); //权益
      // 第二个饼图数据
      let two1 = Number(this.data[0].value[1]); //总负债
      let two2 = Number(this.data[3].value[1]); //权益
      // 第三个饼图数据
      let three1 = Number(this.data[0].value[2]); //总负债
      let three2 = Number(this.data[3].value[2]); //权益
      // 相加总数
      let textOne = one1 + one2;
      let textTwo = two1 + two2;
      let textThree = three1 + three2;
      if (textThree > textOne && textThree > textTwo) {
        this.arr2 = ["40%", "70%"];
      }
      if (textThree > textTwo && textThree < textOne) {
        this.arr2 = ["35%", "65%"];
      }
      if (textThree > textOne && textThree < textTwo) {
        this.arr2 = ["35%", "65%"];
      }
      if (textThree < textOne && textThree < textTwo) {
        this.arr2 = ["25%", "55%"];
      }
      function numFormat(num) {
        var res = num.toString().replace(/\d+/, function (n) {
          // 先提取整数部分
          return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
            return $1 + ",";
          });
        });
        return res;
      }
      var option = {
        color: ["#F7882B", "#529EE2"],
        title: [
          {
            text: numFormat(textThree.toFixed(2))+'（万元）',
            left: "50%",
            top: "87%",
            textAlign: "center",
            textStyle: {
              fontSize: "28px",
              color: "#3A85FD",
            },
          },
          {
            text: this.year[2] + "年负债和所有者权益总计",
            left: "50%",
            top: "81%",
            textAlign: "center",
            textStyle: {
              fontSize: "18px",
              color: "#333",
              fontWeight: 500,
            },
          },
        ],
        series: [
          {
            type: "pie",
            radius: this.arr2,
            center: ["50%", "45%"],
            avoidLabelOverlap: false,
            // itemStyle: {
            //   borderRadius: 3,
            //   borderColor: '#fff',
            //   borderWidth: 2
            // },
            // label: {
            //   position: 'inner',
            //   show: true,
            //   formatter: '{d}%'
            // },
            labelLine: {
              show: false,
            },
            data: [this.data[0].value[2], this.data[3].value[2]],
          },
        ],
        // series: [
        //   {
        //     type: "pie",
        //     radius: this.arr2,
        //     center:["50%", "45%"],
        //     itemStyle: {
        //       borderRadius: 3,
        //       borderColor: "#fff",
        //       borderWidth: 2,
        //     },
        //     label: {
        //       fontSize: 12,
        //       position: "inner", //center表示文本显示位置为内部
        //       formatter:"{d}%"
        //     },
        //     labelLine: {
        //         show: false, //引导线不显示
        //     },
        //     data:[22,55]
        //   },
        // ],
      };
      this.$echarts.init(this.$refs.echartsBigTwo).setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.echarts {
  .adds {
    width: 60%;
    height: 400px;
  }
  position: relative;
  display: flex;
  .echartsLengen {
    .box {
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    position: absolute;
    top: 0px;
    right: 45%;
    display: flex;
    align-items: center;
    .color1 {
      width: 24px;
      height: 14px;
      border-radius: 3px;
      background: #529ee2;
      margin-right: 5px;
    }
    .color2 {
      width: 24px;
      height: 14px;
      border-radius: 3px;
      margin-right: 5px;
      background: #f7882b;
    }
  }
}
</style>

