<template>
  <div ref="echarEle" style="height: 310px"></div>
</template>
<script>
export default {
  props: {
    date: Array,
    data: Array,
    desc: {
      type: Array,
      default: ["能力低", "能力较低", "能力中", "能力较高", "能力高"],
    },
    title: {
      type: String,
      // default: "财务风险历史变化",
    },
  },
  mounted() {
    this.date[0] && this.init();
  },
  methods: {
    init() {
      const data = this.desc;
      const option = {
        title: {
          text: this.title,
          left: "center",
          textStyle: {
            fontWeight: 400,
            fontSize: 14,
          },
        },
        backgroundColor: "#fff",
        color: ["#5E98FE"],
        xAxis: [
          {
            type: "category",
            data: this.date,
            axisLine: {
              show: true,
              alignWithLabel: false,
              lineStyle: {
                color: "#F0F0F0",
                width: "4",
              },
            },
            axisLabel: {
              color: "#999999",
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],

        yAxis: [
          {
            min: 0,
            max: 5,
            show: false,
            type: "value",
            axisLine: {
              show: false,
              alignWithLabel: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            lineStyle: {
              width: 3,
            },
            name: "财务安全度",
            type: "line",
            data: this.data,
            barWidth: 30,
            markLine: {
              symbol: "none",

              data: [
                {
                  name: "风险高",
                  xAxis: 0,
                  yAxis: 1,
                  lineStyle: {
                    color: "#fc7566",
                  },
                  label: {
                    color: "#fc7566",
                  },
                },
                {
                  name: "风险较高",
                  xAxis: 0,
                  yAxis: 2,
                  lineStyle: {
                    color: "#fed64f",
                  },
                  label: {
                    color: "#fed64f",
                  },
                },
                {
                  name: "风险中",
                  xAxis: 0,
                  yAxis: 3,
                  lineStyle: {
                    color: "#1ee2aa",
                  },
                  label: {
                    color: "#1ee2aa",
                  },
                },
                {
                  name: "风险较低",
                  xAxis: 0,
                  yAxis: 4,
                  lineStyle: {
                    color: "#05c3ff",
                  },
                  label: {
                    color: "#05c3ff",
                  },
                },
                {
                  name: "风险低",
                  xAxis: 0,
                  yAxis: 5,
                  lineStyle: {
                    color: "#218dfa",
                  },
                  label: {
                    color: "#218dfa",
                  },
                },
              ].map((item, i) => {
                return { ...item, name: data[i] };
              }),
              label: {
                formatter: "{b}",
              },
            },
          },
        ],
      };
      const myChart = this.$echarts.init(this.$refs.echarEle);
      myChart.setOption(option, true);
    },
  },
  watch: {
    date() {
      setTimeout(this.init.bind(this), 0);
    },
  },
};
</script>
