<template>
  <div class="textBox">
    <div style="margin-top: 15px; width: 50%">
      <div class="titleText">报告日期</div>
      <div class="text_Desc">
        {{ year }}
        <span v-show="report_time == '00'">年报</span>
        <span v-show="report_time == '01'">三季报</span>
        <span v-show="report_time == '02'">半年报</span>
        <span v-show="report_time == '03'">一季报</span>
        <span v-show="report_time == '001'">一月报</span>
        <span v-show="report_time == '002'">二月报</span>
        <span v-show="report_time == '003'">三月报</span>
        <span v-show="report_time == '004'">四月报</span>
        <span v-show="report_time == '005'">五月报</span>
        <span v-show="report_time == '006'">六月报</span>
        <span v-show="report_time == '007'">七月报</span>
        <span v-show="report_time == '008'">八月报</span>
        <span v-show="report_time == '009'">九月报</span>
        <span v-show="report_time == '010'">十月报</span>
        <span v-show="report_time == '011'">十一月报</span>
        <span v-show="report_time == '012'">十二月报</span>
      </div>
    </div>
    <div style="margin-top: 15px; width: 50%">
      <div class="titleText">内容</div>
      <div class="text_sort">指标排名</div>
    </div>
    <div style="margin-top: 15px; width: 50%">
      <div class="titleText">总得分</div>
      <div class="text_sort">{{ total[0] + "%" }}~{{ total[1] + "%" }}</div>
    </div>
    <div v-for="item in list" :key="item" style="margin-top: 15px; width: 50%">
      <div class="titleText">{{ item.name }}</div>
      <div class="text_sort">
        {{ item.area[0] + "%" }}~{{ item.area[1] + "%" }}
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  props: {
    list: Array,
    year: String,
    report_time: String,
    total: Array,
  },
};
</script>

<style lang="less" scoped>
.titleText {
  color: #666;
  font-size: 14px;
}
.text_Desc {
  color: #333;
  font-size: 16px;
}
.textBox {
  display: flex;
  flex-wrap: wrap;
}
.text_sort {
  color: #333;
  font-size: 16px;
}
</style>
