<template>
  <div ref="wrap" style="position: relative; margin-top: 15px">
    <div id="abilitySynthesis">
      <div
        class="test1"
        style="background: #fff"
        v-if="getUserRole('financial_capacity')"
      >
        <div class="title">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          财务能力概览
        </div>
        <div>
          <div class="row justify-content-around">
            <div class id="rad">
              <div class="w-100 bgc_w" style="overflow: hidden">
                <div class="row justify-content-between pr-3">
                  <div
                    style="
                      margin-top: 10px;
                      margin-left: 30px;
                      margin-bottom: 15px;
                      font-weight: 550;
                      color: #333;
                    "
                  >
                    能力分析结果
                  </div>
                  <div class="row align-items-center">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="上年同期能力分析结果"
                      placement="bottom"
                    >
                      <div
                        class="p_box"
                        style="background: #d8e7ff; color: #3a85fd"
                      >
                        {{ pie_data.last_desc }}
                      </div>
                    </el-tooltip>
                    <div
                      class="p_box ml-1 mr-1"
                      style="background: #a6d0fe; color: #fff"
                    >
                      <img
                        src="~@/assets/img/page/right.png"
                        alt=""
                        srcset=""
                        style="width: 30px; height: 20px"
                      />
                    </div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="当期能力分析结果"
                      placement="bottom"
                    >
                      <div
                        class="p_box"
                        style="background: #5797fd; color: #fff"
                      >
                        {{ pie_data.desc }}
                      </div>
                    </el-tooltip>
                  </div>
                </div>

                <div
                  class
                  style="
                    width: 100%;
                    overflow: hidden;
                    transform: translateY(-21px);
                  "
                >
                  <s-pie
                    :data="pie_data.data"
                    :name="pie_data.name"
                    :desc="pie_data.desc"
                    :raduis="120"
                  ></s-pie>
                  <div
                    style="
                      margin-left: 30px;
                      margin-right: 30px;
                      padding: 14px 20px;
                      background: rgb(241, 245, 255);
                      color: #333;
                    "
                  >
                    <p v-for="item in summary" :key="item">{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 flex-column row bgc_w color-4d"
              style="overflow: hidden"
            >
              <div
                style="
                  margin-top: 10px;
                  margin-left: 10px;
                  margin-bottom: 15px;
                  font-weight: 550;
                  color: #333;
                "
              >
                财报分析历史变化
              </div>
              <div>
                <SecurityLine
                  :date="pie_data.safety_index.name"
                  :data="pie_data.safety_index.value"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="test1"
        v-if="getUserRole('structural_analysis')"
        style="background: #fff; margin-top: 20px"
      >
        <div class="title">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          结构趋势分析
        </div>
        <Financial :ObjectData="ObjectData.structure"></Financial>
      </div>

      <div
        class="test1"
        v-if="getUserRole('account_analysis')"
        style="background: #fff; margin-top: 20px"
      >
        <div class="title">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          科目异动分析
        </div>
        <FinancialTwo :ObjectData="ObjectData1.subject"></FinancialTwo>
      </div>

      <div
        class="test1"
        v-if="getUserRole('financial_analysis')"
        style="background: #fff; margin-top: 20px"
      >
        <div class="pt-1 pb-3 pr-30px">
          <div class="title">
            <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
            财务指标分析
          </div>
          <div class="pl-30px">
            <fivePower ref="fivePower" />
          </div>
        </div>
      </div>

      <!-- <div style="background: #fff" class="test1 mt-4 pb-4">
        <div class="title">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          经典杜邦分析
        </div>
      </div> -->

      <div
        v-if="getUserRole('industry_ranking')"
        style="background: #fff"
        class="test1 mt-4 pb-4"
      >
        <div class="title">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          行业排名分析
        </div>
        <div class="row" style="height: 400px; padding: 10px 10px 20px 30px">
          <div style="width: 25%">
            <TextList
              :list="list"
              :year="year"
              :report_time="report_time"
              :total="total"
            ></TextList>
          </div>
          <div style="width: 75%">
            <div ref="eles" style="width: 100%; height: 400px"></div>
          </div>
        </div>
        <div class="tagTexts">
          <span
            style="
              color: rgb(153, 153, 153);
              font-size: 18px;
              margin-bottom: 10px;
            "
            >分析小结：</span
          >
          <div
            class="cl"
            v-for="(item, index) in desc"
            :key="index"
            style="color: rgb(51, 51, 51); font-size: 16px"
          >
            {{ index + 1 + "、" + item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Text from "./text.vue";
import * as sesstion from "@/store/localStorage";
import Financial from "@/components/CompanyTab/FinancialAnalysis/old/Financial";
import FinancialTwo from "@/components/CompanyTab/FinancialAnalysis/old/FinanciayTwo";
import business from "./business.vue";
import profitTable from "../../../Table/profitTable";
import {
  logActivity,
  causeFiveAbilities,
  causeWhitewash,
  toTop,
} from "@/api.js";
import { pie_data } from "@/api/common";
let powerState = {
  盈利能力: "profitTable",
  短期偿债能力: "longrepayTable",
  长期偿债能力: "shortrepayTable",
  营运能力: "opeTable",
  发展能力: "deveTable",
};
export default {
  name: "FinancialAnalysis",
  props: {
    ObjectData: Object,
    ObjectData1: Object,
    showflag: Boolean,
  },
  components: {
    Stable: require("@/components/Table/in_out_table").default,
    FinancialTwo,
    Financial,

    SPie: require("@/components/SPie/index").default,
    SecurityLine: require("@/components/line/SecurityLine").default,
    Summary: require("@/components/summary/summary.vue").default,
    fivePower: require("./FinancialAnalysisComponents/fivePower").default,
    profitTable,
    business,
    TextList: Text,

    AnalysisOfSourceAndUsageDirection:
      require("../AnalysisOfSourceAndUsageDirection/index").default,

    CTable: require("../CashFlowCapacityAnalysis/Ctables.vue").default,
    WaterfallBar: require("../AnalysisOfSourceAndUsageDirection/WaterfallBar")
      .default,
    ABar: require("../AnalysisOfSourceAndUsageDirection/ABar").default,
  },
  data() {
    return {
      desc_arr1: [],
      income_subjects: {},
      tableData: [],
      shows: false,
      company_type: sesstion.getLocalData("company").company_type,
      dataList: {},
      tableType: 2,
      tableList: {},

      year: sesstion.getLocalData("companyData").year,
      list: [],
      desc: [],
      date_tree: {},
      RadarShow: false,
      indicator: [],
      report_time: "00",
      report_type: "00",
      RadarValue: [],
      vm: this,
      w: window,
      showEchartLine: false,
      availableYears: [],
      selectedYear: "",
      category: "tj", // 行业分类
      profitTable: false,
      longrepayTable: false,
      shortrepayTable: false,
      opeTable: false,
      deveTable: false,
      brokenValue: 0,
      healthyValue: 0,
      industry_value: 0,
      series: [],
      date: [],
      date0: [],
      date1: [],
      nameHide: { selected: {} },
      positiveArr: [],
      pie_data: {
        data: [],
        name: "-",
        desc: "-",
      },
      powerEchartData: [
        { series: [], date: [] },
        { series: [], date: [] },
        { series: [], date: [] },
        { series: [], date: [] },
        { series: [], date: [] },
      ],
      powerState: powerState,

      fourMajor: {
        opeData: {
          year: ["2016", "2017", "2018"],
          itemData: [
            /*保留一位小数*/
            { name: "净资产收益率", data: [22.2, 22.2, 22.2] },
            { name: "总资产报酬率", data: [22.2, 22.2, 22.2] },
            { name: "盈余现金保障倍数", data: [22.2, 22.2, 22.2] },
            { name: "成本费用利润率", data: [22.2, 22.2, 22.2] },
            { name: "资本收益率", data: [22.2, 22.2, 22.2] },
            { name: "销售(营业)利润率", data: [22.2, 22.2, 99.9] },
          ],
        },
        opeTbData: {
          year: ["2016-12-31", "2017-12-31", "2018-12-31"],
          itemData: [],
        },
      },
      resultRatar: {
        a: ["50%", "50%"],
        style: {
          width: "100%",
          height: "100%",
        },
        nameFig: [
          { text: "盈利能力", max: 6 },
          { text: "偿债能力", max: 6 },
          { text: "营运能力", max: 6 },
          { text: "发展能力", max: 6 },
        ],
        data: this.abilitiesCode, //雷达图上需要的数值
        radius: 60,
      },
      // 初始化的数据
      stdFinana: {
        Status: "1",
        Message: "操作成功",
        Result: {
          data: [
            {
              category: "盈利能力",
              res: [
                {
                  title: "净资产收益率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0,
                        excel_val: 11.7,
                        avg: 5.7,
                        poor_val: -9.2,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 0,
                        excel_val: 0,
                        avg: 0,
                        poor_val: 0,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0,
                        excel_val: 0,
                        avg: 0,
                        poor_val: 0,
                      },
                    },
                  ],
                },
                {
                  title: "总资产报酬率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0,
                        excel_val: 8.1,
                        avg: 4.3,
                        poor_val: -4.6,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 0,
                        excel_val: 8.7,
                        avg: 4.6,
                        poor_val: -4.3,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0,
                        excel_val: 0,
                        avg: 0,
                        poor_val: 0,
                      },
                    },
                  ],
                },
                {
                  title: "盈余现金保障倍数",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0,
                        excel_val: 10.4,
                        avg: 1.0,
                        poor_val: -2.3,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 0,
                        excel_val: 10.4,
                        avg: 1.0,
                        poor_val: -2.3,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0.11,
                        excel_val: 0,
                        avg: 0,
                        poor_val: 0,
                      },
                    },
                  ],
                },
                {
                  title: "资本收益率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0,
                        excel_val: 13.0,
                        avg: 6.0,
                        poor_val: -7.8,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 0,
                        excel_val: 13.4,
                        avg: 6.2,
                        poor_val: -7.6,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 93.29,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "销售（营业）利润率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0,
                        excel_val: 20.4,
                        avg: 9.0,
                        poor_val: -5.0,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 20.95,
                        excel_val: 19.9,
                        avg: 8.8,
                        poor_val: -5.1,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "成本费用利润率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0,
                        excel_val: 0,
                        avg: 0,
                        poor_val: 0,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 0,
                        excel_val: 0,
                        avg: 0,
                        poor_val: -1,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
              ],
            },
            {
              category: "偿债能力",
              res: [
                {
                  title: "速动比率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 42.59,
                        excel_val: 130.3,
                        avg: 70.0,
                        poor_val: 24.1,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 42.55,
                        excel_val: 133.3,
                        avg: 73.0,
                        poor_val: 27.3,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 33.72,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "现金流动负债比率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 3.82,
                        excel_val: 27.0,
                        avg: 9.5,
                        poor_val: -12.8,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 12.07,
                        excel_val: 27.0,
                        avg: 9.5,
                        poor_val: -12.8,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0.58,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "带息负债比率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 16.78,
                        excel_val: 25.4,
                        avg: 48.0,
                        poor_val: 73.7,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 17.66,
                        excel_val: 25.4,
                        avg: 48.0,
                        poor_val: 73.7,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 20.6,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "资产负债率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 77.7,
                        excel_val: 50.0,
                        avg: 60.0,
                        poor_val: 85.0,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 77.2,
                        excel_val: 50.0,
                        avg: 60.0,
                        poor_val: 85.0,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 78.0,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
              ],
            },
            {
              category: "营运能力",
              res: [
                {
                  title: "存货周转率（次）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0.4,
                        excel_val: 17.0,
                        avg: 4.8,
                        poor_val: 1.1,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 0.32,
                        excel_val: 17.0,
                        avg: 4.8,
                        poor_val: 1.1,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0.32,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "流动资产周转率（次）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0.39,
                        excel_val: 4.1,
                        avg: 1.8,
                        poor_val: 0.5,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 0.32,
                        excel_val: 4.1,
                        avg: 1.8,
                        poor_val: 0.5,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0.34,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "应收账款周转率（次）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 88.79,
                        excel_val: 18.1,
                        avg: 7.2,
                        poor_val: 3.1,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 58.87,
                        excel_val: 17.9,
                        avg: 7.0,
                        poor_val: 2.9,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 54.54,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "总资产周转率（次）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 0.35,
                        excel_val: 1.6,
                        avg: 0.6,
                        poor_val: 0.2,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 0.3,
                        excel_val: 1.6,
                        avg: 0.6,
                        poor_val: 0.2,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 0.32,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
              ],
            },
            {
              category: "发展能力",
              res: [
                {
                  title: "资本积累率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 17.62,
                        excel_val: 32.3,
                        avg: 7.9,
                        poor_val: -18.3,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 9.91,
                        excel_val: 31.9,
                        avg: 7.8,
                        poor_val: -18.5,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 28.37,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "总资产增长率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 20.24,
                        excel_val: 17.6,
                        avg: 7.0,
                        poor_val: -13.9,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 6.09,
                        excel_val: 21.1,
                        avg: 10.5,
                        poor_val: -10.4,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 26.51,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "销售（营业）增长率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 33.58,
                        excel_val: 16.6,
                        avg: 3.5,
                        poor_val: -23.7,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 8.1,
                        excel_val: 20.1,
                        avg: 7.0,
                        poor_val: -20.2,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 31.33,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "成本费用占主营业务收入比重（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 84.63,
                        excel_val: 81.1,
                        avg: 96.2,
                        poor_val: 108.4,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: 85.25,
                        excel_val: 81.4,
                        avg: 96.5,
                        poor_val: 108.7,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 82.78,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
                {
                  title: "销售（营业）利润增长率（%）",
                  alldata: [
                    {
                      year: "2015",
                      data: {
                        cur_val: 28.55,
                        excel_val: 14.3,
                        avg: 2.6,
                        poor_val: -22.3,
                      },
                    },
                    {
                      year: "2014",
                      data: {
                        cur_val: -1.33,
                        excel_val: 16.5,
                        avg: 4.8,
                        poor_val: -18.1,
                      },
                    },
                    {
                      year: "2013",
                      data: {
                        cur_val: 16.05,
                        excel_val: "缺少标准",
                        avg: "缺少标准",
                        poor_val: "缺少标准",
                      },
                    },
                  ],
                },
              ],
            },
          ],
          risk: [
            {
              Category: "",
              year: "",
              Risk: "",
            },
          ],
        },
        缺失数据: [],
        缺失对比: [
          "2013年缺少行业标准:净资产收益率（%）",
          "2013年缺少行业标准:总资产报酬率（%）",
          "2013年缺少行业标准:盈余现金保障倍数",
          "2013年缺少行业标准:资本收益率（%）",
          "2013年缺少行业标准:销售（营业）利润率（%）",
          "2013年缺少行业标准:成本费用利润率（%）",
          "2013年缺少行业标准:速动比率（%）",
          "2013年缺少行业标准:现金流动负债比率（%）",
          "2013年缺少行业标准:带息负债比率（%）",
          "2013年缺少行业标准:资产负债率（%）",
          "2013年缺少行业标准:存货周转率（次）",
          "2013年缺少行业标准:流动资产周转率（次）",
          "2013年缺少行业标准:应收账款周转率（次）",
          "2013年缺少行业标准:总资产周转率（次）",
          "2013年缺少行业标准:资本积累率（%）",
          "2013年缺少行业标准:总资产增长率（%）",
          "2013年缺少行业标准:销售（营业）增长率（%）",
          "2013年缺少行业标准:成本费用占主营业务收入比重（%）",
          "2013年缺少行业标准:销售（营业）利润增长率（%）",
        ],
        其他错误信息: null,
      },
      importantData: {
        Status: "1",
        Message: "操作成功",
        Result: {
          risk: [
            {
              Category: "盈利能力",
              year: "2015",
              Risk: "中",
              Highlight: [
                "总资产报酬率（%）",
                "盈余现金保障倍数",
                "销售（营业）利润率（%）",
              ],
            },
            {
              Category: "盈利能力",
              year: "2014",
              Risk: "低",
            },
            {
              Category: "盈利能力",
              year: "2013",
              Risk: "指标：净资产收益率（%）缺少比较值指标：总资产报酬率（%）缺少比较值指标：盈余现金保障倍数缺少比较值指标：资本收益率（%）缺少比较值指标：销售（营业）利润率（%）缺少比较值指标：成本费用利润率（%）缺少比较值",
            },
            {
              Category: "偿债能力",
              year: "2015",
              Risk: "低",
            },
            {
              Category: "偿债能力",
              year: "2014",
              Risk: "低",
            },
            {
              Category: "偿债能力",
              year: "2013",
              Risk: "指标：速动比率（%）缺少比较值指标：现金流动负债比率（%）缺少比较值指标：带息负债比率（%）缺少比较值指标：资产负债率（%）缺少比较值",
            },
            {
              Category: "营运能力",
              year: "2015",
              Risk: "高",
              Highlight: [
                "存货周转率（次）",
                "流动资产周转率（次）",
                "总资产周转率（次）",
              ],
            },
            {
              Category: "营运能力",
              year: "2014",
              Risk: "高",
              Highlight: [
                "存货周转率（次）",
                "流动资产周转率（次）",
                "总资产周转率（次）",
              ],
            },
            {
              Category: "营运能力",
              year: "2013",
              Risk: "指标：存货周转率（次）缺少比较值指标：流动资产周转率（次）缺少比较值指标：应收账款周转率（次）缺少比较值指标：总资产周转率（次）缺少比较值",
            },
            {
              Category: "发展能力",
              year: "2015",
              Risk: "低",
            },
            {
              Category: "发展能力",
              year: "2014",
              Risk: "中",
              Highlight: [
                "资本积累率（%）",
                "总资产增长率（%）",
                "销售（营业）增长率（%）",
              ],
            },
            {
              Category: "发展能力",
              year: "2013",
              Risk: "指标：资本积累率（%）缺少比较值指标：总资产增长率（%）缺少比较值指标：销售（营业）增长率（%）缺少比较值指标：成本费用占主营业务收入比重（%）缺少比较值指标：销售（营业）利润增长率（%）缺少比较值",
            },
          ],
        },
      },
      allYear: [],
      regTrue: false,
      industry_data: {},
      industry_levelOne_list: [],
      industry_levelTwo_list: [],
      industry_levelThree_list: [],
      industry_levelFour_list: [],
      industry_levelFive_list: [],
      indusLevelOne: {},
      indusLevelTwo: {},
      indusLevelThree: {},
      indusLevelFour: {},
      indusLevelFive: {},
      selectedRange: "0",
      company: {
        S_or_B_code: "",
      }, //  存放公司信息
      isQuery: true,
      maskShow: true,
      selectText: "", //请选择
      noDataText: "", // 无数据
      firstValue: "工业", // 一级行业的value值

      EchartDataParam: {},
      summary: "",
      SecurityLineData: [],
      SecurityLineDate: [],
      total: [],
    };
  },
  watch: {},
  activated() {
    if (!this.isQuery) {
      this.showDownload();
    } else {
      this.hideDownload();
    }
  },
  deactivated() {
    if (!this.isQuery) {
      this.hideDownload();
    }
  },
  mounted() {
    this.company = sesstion.getLocalData("company");

    this.opeChart();
  },
  created() {},

  methods: {
    thousandBitSeparator: function (cellValue) {
      let value = cellValue / 10000;
      // console.log(value);
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },

    startA(param) {
      let params = {
        ...param,
        coefficient: "0.5",
      };
      // 排名
      if (this.getUserRole("industry_ranking")) {
        toTop(params).then((res) => {
          this.list = res.data.data.chart;
          this.total = res.data.data.total.position;
          this.desc = res.data.data.desc;
          setTimeout(() => {
            this.initBar();
            this.$loadingHide();
          }, 500);
        });
      }
      // 财务能力概览
      if (this.getUserRole("financial_capacity")) {
        this.set_pie_data(param);
      }

      var param = {
        ...param,
        Stock_code: param.stock_code,
        industry_range: 0,
      };
      // 五大能力
      if (this.getUserRole("financial_analysis")) {
        setTimeout(() => {
          this.$refs.fivePower.getData({
            ...param,
            stock_code: param.Stock_code,
            report_year: param.year,
            company_name: param.name,
          });
          this.$loadingHide();
        }, 1000);
      }

      this.category = param.industry_code.substr(0, 2);
      this.report_time = param.report_time;

      if (param.stock_code) {
        // 上市公司传递的参数
        logActivity({
          company_code: param.company_code,
          menu_id: "3",
          parsing_time: param.year,
          industry_type: param.industry_name
            ? param.industry_name.split("/").reverse()[0]
            : "",
          industry_number: param.industry_code,
          industry_range: 0,
          report_time: param.report_time,
          report_type: param.report_type,
        });
      } else {
        // 非上市公司传递的参数

        logActivity({
          company_code: param.company_code,
          menu_id: "3",
          parsing_time: param.year,
          industry_type: param.industry_name
            ? param.industry_name.split("/").reverse()[0]
            : "",
          industry_number: param.industry_code,
          industry_range: 0,
          report_time: param.report_time,
          report_type: param.report_type,
        });
      }
    },

    set_pie_data(param) {
      if (this.company.company_type >= "3") {
        causeFiveAbilities({
          ...param,
          report_year: param.year,
        }).then(({ data }) => {
          this.pie_data = {
            data: data.ability.data,
            desc: data.ability.desc,
            name: data.ability.name,
          };
          this.pie_name = data.ability.name;
          this.pie_desc = data.ability.desc;
          this.summary = [data.ability.summary];
          causeWhitewash({
            ...param,
            company_type: this.company.company_type,
            report_year: param.year,
          }).then(({ data }) => {
            this.SecurityLineData = data.risk_desc;
            this.SecurityLineDate = data.health_year;
            this.$loadingHide();
          });
          this.$loadingHide();
        });
      } else {
        pie_data({
          ...param,
          industry_range: "0",
          report_year: param.year,
          company_name: param.name,
        }).then(({ data }) => {
          this.pie_data = data;
          this.SecurityLineData = data.safety_index.desc;
          this.SecurityLineDate = data.safety_index.name;
          this.summary = data.summary_txt;
          this.$loadingHide();
        });
      }
    },
    // 行业排名
    initBar() {
      const title = [];
      const data = [];
      if (this.list && this.list.length) {
        for (var i = this.list.length - 1; i >= 0; i--) {
          var row = this.list[i];
          title.push(row.name);
          data.push(100 - row.pos_val);
        }
      }
      let option = {
        grid: {
          top: 5,
        },

        xAxis: {
          type: "value",
          show: true,
          min: 0,
          max: 100,
          // minInterval:10,
          // maxInterval:32.5,
          interval: 10,
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: [
                "#ccc",
                "#ccc",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#ccc",
              ],
            },
          },
          axisLabel: {
            formatter: function (value, i) {
              // console.log(value,i);
              var texts = [];
              if (i == 1) {
                texts.push("{LabelStyle1|\n\n排名前\n90 - 100%}");
              } else if (i == 2) {
                texts.push("{LabelStyle2|\n\n排名前\n67.5 - 90%}");
              } else if (i == 5) {
                texts.push("{LabelStyle3|\n\n排名前\n32.5 - 67.5%}");
              } else if (i == 8) {
                texts.push("{LabelStyle4|\n\n排名前\n10 - 32.5%}");
              } else if (i == 10) {
                texts.push("{LabelStyle1|\n\n排名前\n0 - 10%}");
              }
              return texts;
            },
            rotate: 0.1,
            // padding: 10,
            rich: {
              LabelStyle1: {
                padding: 10,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle2: {
                padding: -20,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle3: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle4: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
            },
          },
        },
        yAxis: {
          type: "category",
          data: title,
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            name: "排名前0-10%",
            type: "bar",
            barWidth: "50%",
            data: data,
            color: "#529EE2",
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.data <= 10) {
                    return "#F2637B";
                  }
                  if (params.data <= 32.5) {
                    return "#F88E36";
                  }
                  if (params.data > 32.5 && params.data <= 67.5) {
                    return " #FAD33F";
                  }
                  if (params.data <= 90) {
                    return "#3EBB9F";
                  }
                  if (params.data <= 100) {
                    return "#529EE2";
                  }
                },
              },
            },
          },
          {
            name: "排名前10-32.5%",
            type: "bar",
            color: "#3EBB9F",
            barWidth: "0%",
          },
          {
            name: "排名前32.5-67.5%",
            barWidth: "0%",
            type: "bar",
            color: "#FAD33F",
          },
          {
            name: "排名前67.5-90%",
            barWidth: "0%",
            type: "bar",
            color: "#F88E36",
          },
          {
            name: "排名前90-100%",
            barWidth: "0%",
            type: "bar",
            color: "#F2637B",
          },
        ],
      };
      this.$echarts.init(this.$refs.eles).setOption(option);
    },

    // 盈利能力
    opeChart() {
      let allData = this.stdFinana.Result;
      let colorA = this.fourMajor.opeData.color;
      let year = [];
      let splitData = [
        ...allData.data[0].res.slice(0, 2),
        ...allData.data[0].res.slice(3),
      ]; // 获取除了【盈余现金保障倍数】之外的数据

      let sL = splitData.length;
      let spcilData = [...allData.data[0].res.slice(2, 3)]; // 获取【盈余现金保障倍数】的数据
      let sp = spcilData[0].alldata; // 获取【盈余现金保障倍数】的数据
      let d = [];
      let dSp = [];
      // 获取除了【盈余现金保障倍数】之外的数据
      for (let t of splitData) {
        d.push(t.alldata);
      }
      // 获取【盈余现金保障倍数】的数据
      for (let s of sp) {
        dSp.push(s.data.cur_val);
      }
      // dSp.reverse()
      // 全局可视化图的年份
      for (let u of d[0]) {
        year.push(u.year);
      }
      this.allYear = year.reverse();
      let seriesColor = [
        "#f47150",
        "#70ddf1",
        "#6ea7fe",
        "#a3d9ff",
        "#b9ef6e",
        "#2bf891",
        "#f3a169",
        "#c4a6e9",
      ];

      //【盈余现金保障倍数】 可视化图渲染
    },
    // 偿债能力
    solvency() {
      let allData = this.stdFinana.Result;
      let splitData = [
        ...allData.data[1].res.slice(0, 1),
        ...allData.data[1].res.slice(2),
      ]; // 获取除了【速动比率】之外的数据
      let spcilData = [...allData.data[1].res.slice(1, 2)]; // 获取【速动比率】的数据
      let sp = spcilData[0].alldata; // 获取【速动比率】的数据
      let soL = splitData.length;
      let d = [];
      let k = [[], [], [], []];
      let dSp = [];
      // 获取除了【速动比率】之外的数据
      for (let t of splitData) {
        d.push(t.alldata);
      }
      // 获取除了【速动比率】之外的数据
      for (let t of splitData) {
        d.push(t.alldata);
      }
      // 获取【速动比率】的数据
      for (let s of sp) {
        dSp.push(s.data.cur_val);
      }
      dSp.reverse();
      let seriesColor = [
        "#f47150",
        "#70ddf1",
        "#6ea7fe",
        "#a3d9ff",
        "#b9ef6e",
        "#2bf891",
        "#f3a169",
        "#c4a6e9",
      ];
      for (let i = 0; i < soL; i++) {
        for (let u of d[i]) {
          k[i].push(u.data.cur_val);
        }
        k[i].reverse();
        let sovChart = this.$echarts.init(document.getElementById("solv" + i));
        sovChart.setOption({
          tooltip: {
            formatter: "{a0}: {c0}%",
          },
          legend: {
            selectedMode: false,
            data: splitData[i].title.split(),
          },
          xAxis: {
            type: "category",
            data: this.allYear,
          },
          yAxis: {
            type: "value",
            name: "%", //单位待确认
          },
          series: [
            {
              name: splitData[i].title,
              data: k[i],
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [20, 20, 0, 0],
                  color: seriesColor[i],
                },
              },
            },
          ],
        });
      }
      //【速动比率】 可视化图渲染
      let speed = this.$echarts.init(document.getElementById("speed"));
      speed.setOption({
        tooltip: {
          formatter: "{a0}: {c0}%",
        },
        legend: {
          data: spcilData.title,
        },
        xAxis: {
          type: "category",
          data: this.allYear,
        },
        yAxis: {
          type: "value",
          name: "%", //单位待确认
        },
        series: [
          {
            name: spcilData[0].title,
            data: dSp,
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [20, 20, 0, 0],
                color: ["#f3a169"],
              },
            },
          },
        ],
      });
    },
    // 营运能力
    camp() {
      let allData = this.stdFinana.Result;
      let splitData = [...allData.data[2].res]; // 获取除了【速动比率】之外的数据
      let soL = splitData.length;
      let d = [];
      let k = [[], [], [], []];
      let dSp = [];
      // 获取数据
      for (let t of splitData) {
        d.push(t.alldata);
      }
      // 获取数据
      for (let t of splitData) {
        d.push(t.alldata);
      }
      let seriesColor = [
        "#f47150",
        "#70ddf1",
        "#6ea7fe",
        "#a3d9ff",
        "#b9ef6e",
        "#2bf891",
        "#f3a169",
        "#c4a6e9",
      ];
      for (let i = 0; i < soL; i++) {
        for (let u of d[i]) {
          k[i].push(u.data.cur_val);
        }
        k[i].reverse();
        let sovChart = this.$echarts.init(document.getElementById("camp" + i));
        sovChart.setOption({
          tooltip: {
            formatter: "{a0}: {c0}次",
          },
          legend: {
            selectedMode: false,
            data: splitData[i].title.split(),
          },
          xAxis: {
            type: "category",
            data: this.allYear,
          },
          yAxis: {
            type: "value",
            name: "次", //单位待确认
          },
          series: [
            {
              name: splitData[i].title,
              data: k[i],
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [20, 20, 0, 0],
                  color: seriesColor[i],
                },
              },
            },
          ],
        });
      }
      // let campChart = this.$echarts.init(document.getElementById('camp'))
    },
    // 发展能力
    prov() {
      //camp
      let allData = this.stdFinana.Result;
      let splitData = [...allData.data[3].res]; // 获取除了【速动比率】之外的数据
      let soL = splitData.length;
      let d = [];
      let k = [[], [], [], [], []];
      let dSp = [];
      // 获取数据
      for (let t of splitData) {
        d.push(t.alldata);
      }
      // 获取数据
      for (let t of splitData) {
        d.push(t.alldata);
      }
      let seriesColor = [
        "#f47150",
        "#70ddf1",
        "#6ea7fe",
        "#a3d9ff",
        "#b9ef6e",
        "#2bf891",
        "#f3a169",
        "#c4a6e9",
      ];
      for (let i = 0; i < soL; i++) {
        for (let u of d[i]) {
          k[i].push(u.data.cur_val);
        }
        k[i].reverse();
        let proChart = this.$echarts.init(document.getElementById("pro" + i));
        proChart.setOption({
          tooltip: {
            formatter: "{a0}: {c0}%",
          },
          legend: {
            selectedMode: false,
            data: splitData[i].title.split(),
          },
          xAxis: {
            type: "category",
            data: this.allYear,
          },
          yAxis: {
            type: "value",
            name: "%", //单位待确认
          },
          series: [
            {
              name: splitData[i].title,
              data: k[i],
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [20, 20, 0, 0],
                  color: seriesColor[i],
                },
              },
            },
          ],
        });
      }
    },
  },
};
</script>
<style lang="less">
.btnActive {
  background: #3a85fd;
  color: #fff;
  border: 1px solid #3a85fd;
}

#AnalysisOfSourceAndUsageDirection {
  background: #fff;
  .table_structure {
    padding: 0 20px;
    .content_title {
      font-size: 16px;
      color: #333333;
      font-weight: 550;
    }
  }
}
.TitleBoxs {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background: #1989fa;
  margin-right: 10px;
  margin-top: 10px;
}

.cl {
  display: flex;

  // align-items: center;
}

.p_box {
  border: none;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  width: 60px;
  height: 20px;
  transform: skew(-25deg);
  border-radius: 3px;
}

.tagText {
  margin-top: 10%;
  width: 100%;
  background: rgb(241, 245, 255);
  padding: 20px;
}

.tagTexts {
  width: 96%;
  margin: 0 auto;
  background: rgb(241, 245, 255);
  padding: 20px;
}

.titles {
  align-items: center;

  span {
    font-size: 16px;
    color: #333;
    font-weight: 550;
  }

  div {
    width: 5px;
    height: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: dodgerblue;
    align-items: center;
    margin-right: 10px;
  }
}

.title {
  font-size: 20px;
  font-weight: 550;
  color: #333;
  background: #fff;
  padding: 20px;

  img {
    margin-right: 5px;
  }
}

#rad {
  min-height: 300px;
  width: 49%;
  overflow: hidden;
}

.mb_tj {
  width: 165px;
  margin-left: 30px;
}

.mb_select {
  width: 563px;
}

// @import "~/assets/css/tebItem/tabItem.css";
.RadioGroup-box {
  line-height: 30px;

  label {
    margin: 0;
    font-size: 15px;
  }
}

.table_bg001 .new_add td {
  -webkit-animation-name: mycolor;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
}

.newlist_box {
  width: 365px;
  float: left;
  margin-right: 10px;
}

.col_l_03 {
  width: 750px;
  float: left;
  overflow: hidden;
}

.newlist_box ul {
  margin: 5px 10px;
}

.newlist_box li {
  line-height: 25px;
}

.newlist_box li a {
  color: #666;
}

.newlist_box li .time {
  float: right;
}

.more_cont {
  clear: both;
  text-align: right;
  padding: 0 10px;
  margin: 5px;
}

.shangzhen_index table {
  table-layout: fixed;
}

.shangzhen_index table td {
  padding-left: 5px;
  text-align: left;
}

.shangzhen_index .dataList,
.shangzhen_index .mystock_list {
  height: 481px;
  margin-top: 3px;
  overflow: hidden;
  background-color: #fff;
}

.shangzhen_index .title_02 {
  padding: 0 4px;
}

.ff_fix table td {
  height: 30px;
}

.pdf-margin {
  margin-top: 18rem !important;
}

.pdf-margin-fznl {
  margin-top: 25rem !important;
}

.mystock_list table {
  table-layout: auto;
}

.mystock_list table td {
  padding: 0;
}

.dbbottom_line {
  border-bottom: 1px solid #dcdddd;
}

.dbbg01 {
  line-height: 21px;
  font-weight: bold;
  color: #000;
  background: #a9cbff;
  border: 1px solid #a9cbff;
  font-size: 14px;
}

.dbbg02 {
  border: 1px solid #c9d9e2;
  line-height: 20px;
  color: #f2687d;
  font-size: 14px;
}

.dbbg03 {
  border: 1px solid #c9d9e2;
  line-height: 20px;
  color: #5a91fe;
  font-size: 14px;
}

.dbfh01 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.border-left-c6 {
  /*#cbd7e6*/
  border-left: 2px solid #cbd7e6;
}

.border-right-c6 {
  border-right: 2px solid #cbd7e6;
}

.border-bottom-c6 {
  border-bottom: 2px solid #cbd7e6;
}

.color-9e4 {
  color: #9ebbe4 !important;
}

.color-9e4 {
  color: #9ebbe4 !important;
}

.green {
  /*低风险*/
  color: green;
}

.midle {
  /* 中风险*/
  color: #cd9b1d;
}

.high {
  /* 高风险*/
  color: red;
}

.mask {
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
}

.bgc-72 {
  background-color: #757272 !important;
}

.title-p {
  padding: 20px 20px;
  margin-top: 20px;
  line-height: 32px;
  background: #fff;

  > div:nth-child(1) {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    opacity: 1;
    position: relative;
    padding-left: 20px;
    color: #000;

    .title-p-line {
      position: absolute;
      width: 6px;
      background: #238efa;
      height: 25px;
      left: 0;
      top: 14%;
      border-radius: 5px;
    }

    .click {
      color: #238efa;
      font-size: 16px;
      margin-left: 20px;
    }
  }
}

@media (max-width: 460px) {
  .pr-30px {
    padding-right: 5px !important;
  }

  .pl-30px {
    padding-left: 5px !important;
  }

  #rad {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0px !important;
  }

  .mb_select {
    width: 100%;
  }

  .mb_tj {
    margin-left: 0px;
  }
}
</style>
