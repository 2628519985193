<template>
  <div
    id="AnalysisOfSourceAndUsageDirection"
    v-if="company_type == '0' || !company_type"
  >
    <div style="background: #fff; padding-bottom: 20px; color: #333">
      <div
        class="row dd"
        style="
          font-size: 18px;
          color: #333;
          align-items: center;
          padding-left: 25px;
        "
      >
        <div class="d" style="margin-left: 10px"></div>
        <span>资本结构分析 </span>
      </div>

      <div class="content_box">
        <div
          class="content"
          v-if="
            (ObjectData[0].current.FB.content[2].value[0] == '0.00' &&
              ObjectData[0].current.FB.content[1].value[0] == '0.00') ||
            (ObjectData[0].current.FB.content[5].value[0] == '0.00' &&
              ObjectData[0].current.FB.content[4].value[0] == '0.00')
          "
        >
          <div class="content_title" style="color: #333">
            <b>
              <!-- <span>{{ ObjectData[0].al_period }}年 </span> -->
              资产负债结构
            </b>
          </div>
          <div class="assets">
            <img src="@/assets/img/NewProject/nodata.svg" />
          </div>
          <div style="text-align: center">数据极度异常，暂无法展示</div>
        </div>
        <div v-else class="content">
          <div class="content_title">
            <b>
              <span>{{ ObjectData[0].al_period }}</span>
              资产负债结构</b
            >
          </div>
          <!-- "#529EE2", "#91C6FB", "#C0CFFF", "#FAD33F", "#F88E36" -->
          <div
            class="row justify-content-center"
            style="text-align: center; margin-bottom: 20px"
          >
            <label
              v-for="(d, x) in seriers"
              :key="x"
              class="row"
              style="align-items: center"
            >
              <div class="lend" :style="{ backgroundColor: d.col }"></div>
              <div class="fz-12 ml-1">{{ d.title }}</div>
            </label>
          </div>
          <div class="content_chart">
            <div class="row h-260px">
              <div class="fz-14 mb_count_ch">
                <div
                  class="text-center w-95px row align-items-center justify-content-center"
                  :style="{
                    height: ObjectData[0].current.FB.content[2].rate[0],
                    backgroundColor: '#529EE2',
                  }"
                >
                  <div>{{ ObjectData[0].current.FB.content[2].rate[0] }}</div>
                </div>
                <div
                  class="text-center w-95px row align-items-center justify-content-center"
                  :style="{
                    height: ObjectData[0].current.FB.content[1].rate[0],
                    backgroundColor: '#91C6FB',
                  }"
                >
                  <div>{{ ObjectData[0].current.FB.content[1].rate[0] }}</div>
                </div>
              </div>
              <div class="fz-14 mb_count_ch">
                <div
                  class="text-center w-95px row align-items-center justify-content-center"
                  :style="{
                    height: ObjectData[0].current.FB.content[5].rate[0],
                    backgroundColor: '#C0CFFF',
                  }"
                >
                  <div>{{ ObjectData[0].current.FB.content[5].rate[0] }}</div>
                </div>
                <div
                  class="text-center w-95px row align-items-center justify-content-center"
                  :style="{
                    height: ObjectData[0].current.FB.content[4].rate[0],
                    backgroundColor: '#FAD33F',
                  }"
                >
                  <div>{{ ObjectData[0].current.FB.content[4].rate[0] }}</div>
                </div>
                <div
                  class="text-center w-95px row align-items-center justify-content-center"
                  :style="{
                    height: ObjectData[0].current.FB.content[7].rate[0],
                    backgroundColor: '#F88E36',
                  }"
                >
                  <div>{{ ObjectData[0].current.FB.content[7].rate[0] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content">
          <div class="content_title"><b>资产负债结构变动</b></div>
          <echartsOne :data="ObjectData[0].history"></echartsOne>
        </div>
      </div>
      <div
        style="
          margin-left: 30px;
          margin-right: 30px;
          padding: 14px 20px 14px 20px;
          background: #f1f5ff;
        "
      >
        <div style="color: #999999; font-size: 18px; margin-bottom: 10px">
          分析小结：
        </div>
        <div
          class="cl"
          style="color: #333; font-size: 16px"
          v-for="(item, i) in ObjectData[0].desc"
          :key="i"
        >
          <!-- <div class="TitleBox" v-show="item !== ''"></div> -->
          <div v-if="i < 2">{{ item }}</div>
          <div v-else>{{ i + 1 + "、" + item }}</div>
        </div>
      </div>
    </div>

    <!-- 饼图+表格 -->
    <div style="background: #fff; padding-bottom: 20px">
      <div class="title">
        <div
          class="row dd"
          style="
            align-items: center;
            font-size: 18px;
            color: #333;
            padding-left: 25px;
          "
        >
          <div class="d"></div>
          <span> 资产结构分析 </span>
        </div>
      </div>
      <div style="margin-top: 10px; padding-left: 30px; padding-right: 30px">
        <zichantable
          v-bind:tableData="ObjectData[1].data"
          :year="ObjectData[1].year"
        >
        </zichantable>
      </div>
      <div
        style="
          margin-left: 30px;
          margin-right: 30px;
          padding: 14px 20px 14px 20px;
          background: #f1f5ff;
        "
      >
        <div style="color: #999999; font-size: 18px; margin-bottom: 10px">
          分析小结：
        </div>
        <div
          class="row align-items-center"
          style="color: #333; font-size: 16px"
          v-for="(item, i) in ObjectData[1].desc[0]"
          :key="i"
        >
          {{ i + 1 + "、" + item }}
        </div>
      </div>
    </div>

    <div style="background: #fff; padding-bottom: 20px">
      <div class="title">
        <div
          class="row dd"
          style="
            align-items: center;
            font-size: 18px;
            color: #333;
            padding-left: 25px;
          "
        >
          <div class="d"></div>
          <span>负债和所有者权益分析</span>
        </div>
      </div>
      <div style="padding-left: 30px; margin-top: 10px; padding-right: 30px">
        <mytable
          v-bind:tableData="ObjectData[2].data"
          :year="ObjectData[2].year"
        >
        </mytable>
      </div>

      <div
        style="
          margin-left: 30px;
          margin-right: 30px;
          padding: 14px 20px 14px 20px;
          background: #f1f5ff;
        "
      >
        <div style="color: #999999; font-size: 18px; margin-bottom: 10px">
          分析小结：
        </div>
        <div
          class="row align-items-center"
          style="color: #333; font-size: 16px"
          v-for="(item, i) in ObjectData[2].desc[0]"
          :key="i"
        >
          {{ i + 1 + "、" + item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as sesstion from "@/store/localStorage";
import echartsOne from "./FinancialEcharts.vue";
import mytable from "./FinancialTable";
import zichantable from "@/components/Table/FinancialTable";
import FinancialEchartsBig from "./FinancialEchartsBig.vue";
import FinancialEchartsBigTwo from "./FinancialEchartsBigTwo.vue";
import { financialEcharts } from "@/api/common";
export default {
  props: {
    ObjectData: Object,
  },
  components: {
    CTitle: require("@/components/title/title.vue").default,
    SummaryText: require("@/components/SummaryText/index.vue").default,
    echartsOne,
    FinancialEchartsBig,
    FinancialEchartsBigTwo,
    mytable,
    zichantable,
  },
  data() {
    return {
      flag1: true,
      flag2: true,
      flag3: true,
      showTable: false,
      showTable1: false,

      company_type: sesstion.getLocalData("company").company_type,
      seriers: [
        {
          col: "#529EE2",
          title: "流动资产合计",
        },
        {
          col: "#91C6FB",
          title: "非流动资产合计",
        },
        {
          col: "#C0CFFF",
          title: "流动负债合计",
        },
        {
          col: "#FAD33F",
          title: "非流动负债合计",
        },
        {
          col: "#F88E36",
          title: "所有者权益合计",
        },
      ],
    };
  },
  created() {
    this.$VM.AnalysisOfSourceAndUsageDirection = this;
  },
  mounted() {
    this.sysTemRuleChart();
    this.addfinancialEcharts();
  },
  methods: {
    heightRate(data, nowRate) {
      let heightRate = nowRate;
      let tatol = 0;
      data.f.forEach((item, i) => {
        if (i > 1) {
          tatol += Math.abs(parseFloat(item.rate.split("%")));
        }
      });
      if (Math.round(tatol) > 100) {
        heightRate =
          (
            Math.abs(parseFloat(nowRate.split("%"))) / Math.round(tatol)
          ).toFixed(2) *
            100 +
          "%";
      }
      return heightRate;
    },

    lock1(flag) {
      if (flag == true) {
        this.flag1 = false;
      } else {
        this.flag1 = true;
      }
    },
    lock2(flag) {
      if (flag == true) {
        this.flag2 = false;
      } else {
        this.flag2 = true;
      }
    },
    lock3(flag) {
      if (flag == true) {
        this.flag3 = false;
      } else {
        this.flag3 = true;
      }
    },
    toggleTable(falg) {
      this.showTable = falg;
    },
    toggleTable1(falg) {
      this.showTable1 = falg;
    },
    addfinancialEcharts(params) {
      // console.log(params);
      let add = sesstion.getLocalData("company");
      // console.log(add);
      financialEcharts({
        add: params,
      }).then((res) => {
        // console.log(res);
      });
    },
    heightRate(data) {
      let tatolleft = 0;
      let tatolright = 0;
      let leftList = [
        data[3].rate[0],
        data[6].rate[0],
        data[5].rate[0],
        data[4].rate[0],
        data[7].rate[0],
      ];
      leftList.forEach((item, i) => {
        if (i < 2) {
          tatolleft += Math.abs(parseFloat(item.split("%")));
        } else {
          tatolright += Math.abs(parseFloat(item.split("%")));
        }
      });
      // debugger
      if (Math.round(tatolleft) > 100) {
        leftList.splice(
          0,
          2,
          (
            Math.abs(parseFloat(data[3].rate[0].split("%"))) /
            Math.round(tatolleft)
          ).toFixed(2) *
            100 +
            "%",
          (
            Math.abs(parseFloat(data[6].rate[0].split("%"))) /
            Math.round(tatolleft)
          ).toFixed(2) *
            100 +
            "%"
        );
      }
      if (Math.round(tatolright) > 100) {
        leftList.splice(
          2,
          3,
          (
            Math.abs(parseFloat(data[5].rate[0].split("%"))) /
            Math.round(tatolleft)
          ).toFixed(2) *
            100 +
            "%",
          (
            Math.abs(parseFloat(data[4].rate[0].split("%"))) /
            Math.round(tatolleft)
          ).toFixed(2) *
            100 +
            "%",
          (
            Math.abs(parseFloat(data[7].rate[0].split("%"))) /
            Math.round(tatolleft)
          ).toFixed(2) *
            100 +
            "%"
        );
      }
      return leftList;
    },
  },
};
</script>
<style lang="less" scoped>
.cl {
  display: flex;

  .TitleBox {
    display: inline-block;
    border-radius: 50%;
    width: 5px !important;
    height: 5px;
    background: #1989fa;
    margin-right: 8px;
    margin-top: 9px;
  }

  // align-items: center;
}
.lend {
  width: 24px;
  height: 14px;
  border-radius: 3px;
  margin-left: 15px;
}
.h-260px {
  height: 260px;
  overflow: hidden;
}
.w-95px {
  width: 140px;
}
.TitleBox {
  display: inline-block;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background: #1989fa;
  margin-right: 8px;
  // margin-top: 10px;
}

.el-button {
  font-size: 14px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 0px;
}

.titless {
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 400;
}

#AnalysisOfSourceAndUsageDirection {
  //   background: #fff;
  // margin-top: 10px;
  .dd {
    // padding-left: 25px;
    span {
      font-weight: 550;
    }

    .d {
      width: 5px;
      height: 20px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background: dodgerblue;
      align-items: center;
      margin-right: 10px;
    }
  }

  .assets {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }

  .c_box {
    padding-left: 15px;
    border-bottom: 1px solid #f0f0f0;
    position: relative;

    .text {
      position: absolute;
      top: 25px;
      right: 20px;
      font-size: 16px;
      color: #333333;
    }
  }

  .table-eachrt-box {
    margin-left: 1043px;
    margin-top: 5px;
    padding: 2px 3px;
    opacity: 0.29;
    border-radius: 2px;
    background-color: #333;
    width: 90px;
    height: 35px;
    display: flex;
    justify-content: flex-end;

    div:nth-child(1) {
      margin-right: 8px;
    }

    div {
      padding: 2px;
      width: 38px;
      text-align: center;
      background: #e2e2e2;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .active {
      background: #1989fa;
      color: #333;
    }
  }

  .content_box {
    padding: 0 25px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .content_chart {
      display: flex;
      justify-content: center;
      position: relative;

      .posion {
        position: absolute;
        top: 0;
        left: 210px;
      }
    }

    .chart_box {
      width: 30px;
    }

    .colors {
      background: #529ee2;
    }

    .colors1 {
      background: #f7882b;
    }

    .color2 {
      background: #d8d8d8;
    }

    .cm2 {
      padding-right: 5px;
      padding-left: 5px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .cm2_one {
      padding-left: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .borderstyle {
      border-top: 1px solid #fff;
    }

    .chart_left {
      width: 24%;
      height: 300px;
      // display: flex;
      // justify-content: space-between;
      background: #e2e2e2;
    }

    .chart_right {
      margin-left: 20px;
      width: 30%;
      height: 300px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .posaiion {
        position: absolute;
        top: 0;
        left: 30px;
      }

      .rightTop {
        width: 100%;
        background: #e2e2e2;
      }

      .rightBottom {
        margin-top: 1px;
        width: 100%;
        background: #e2e2e2;
        display: flex;
        justify-content: space-between;
      }

      .right1 {
        height: 300px;
        display: flex;
        justify-content: space-between;

        .rightTwo {
          flex: 1;

          .rightTwo1 {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    .content {
      width: 49%;
      padding: 0 20px;
      padding-bottom: 20px;
      // min-height: 400px;

      // box-shadow: 0 2px 6px 0 #e9ebee;
      > .content_title {
        font-size: 16px;
        color: #333333;
        padding: 16px 20px;
      }

      > .unit {
        position: relative;
        z-index: 1;
        font-size: 14px;
        color: #999999;
        float: right;
        margin-top: 56px;
        margin-right: 20px;
      }

      .left {
        margin-top: 20px;

        font-size: 16px;
        color: #333333;

        > div {
          margin-bottom: 10px;
        }
      }

      > .right {
        width: 100%;
        margin-top: 20px;

        > div {
          margin-bottom: 10px;

          .content_item {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 460px) {
  .mb_box_ch {
    flex-direction: column;
  }
  .mb_tab_ec {
    display: none;
  }
  .mb_count_ch {
    flex: 1;
    .w-95px {
      width: 100%;
    }
  }
  .mb_jian {
    display: none;
  }
}
</style>
