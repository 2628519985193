<template>
  <div class="table-responsive-sm" style="color:#333;font-size:16px;">
    <table class="table  text-center table-striped">
      <thead class="bgc-be" style="color:#333;font-size:16px">
        <tr>
          <th rowspan="2" valign="middle" align="center" scope="row">项目</th>
          <th colspan="2" v-for="(cell, index) in years" :key="index">
            {{ cell }}
            <span v-if="report_time == '00'">年度</span>
            <span v-if="report_time == '01'">三季度</span>
            <span v-if="report_time == '02'">半年度</span>
            <span v-if="report_time == '03'">一季度</span>
          </th>
        </tr>
        <tr>
          <template v-for="(year, index) in years">
            <th style="background:#F6FAFF;" v-if="tableData.length" :colspan="index === 0 ? 2 : ''" :key="year + '_1'">金额(万元)
            </th>
            <th style="background:#F6FAFF;" v-if="tableData.length && index !== 0" :key="year + '_2'">同比增减</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in tableData" :key="index" class="color-4d" style="color:#333;font-size:16px">
          <th scope="row" class="text-left" v-if="data.title !== '经营活动现金流入' && data.title !== '经营活动现金流出'">{{ data.title }}
          </th>
          <template v-for="(year, yearIndex) in years">
            <th :key="year + '_1'" :colspan="yearIndex === 0 ? 2 : ''"
              v-if="data.title !== '经营活动现金流入' && data.title !== '经营活动现金流出'">
              {{ thousandBitSeparator(data.value[yearIndex]) }}</th>
            <th :key="year + '_2'" v-if="yearIndex !== 0 && data.title !== '经营活动现金流入' && data.title !== '经营活动现金流出'">{{
              data.compare[yearIndex] }}</th>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: Array,
    years: Array,
    report_time: String,
  },
  data() {
    return {
      secondLine: {
        titleItem: ["金额", "金额", "同比增减", "金额", "同比增减"]
      }
    };
  },
  mounted() {
    console.info('tableData', this.tableData)
    console.info('year', this.years)
  },
  methods: {
    thousandBitSeparator: function (num) {
      return num && (num
        .toString().indexOf('.') != -1 ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
          return $1 + ",";
        }) : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
          return $1 + ",";
        }));


    },
  },
};
</script>

<style>
.table thead th {
  vertical-align: middle !important;
  border: 0px solid transparent;
}

.table td,
.table th {
  border-top: 0px solid transparent;
}
</style>
