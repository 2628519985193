<template>
  <div class="table-responsive-sm" style="color:#333;font-size:16px;">
    <!-- <table class="table table-bordered text-center table-striped"> -->
      <table class="table text-center table-striped" style="color:#333;font-size:16px;">
      <thead class="bgc-be color-0">
        <tr>
          <th rowspan="2" valign="middle" align="center" scope="row">项目</th>
          <th :colspan="year.length == 3 ? 3 : (year.length == 2 ? 6 : 12)"
            v-for="cell in year" :key="cell"
          >{{cell}}</th>
        </tr>
        <tr style="background:#F6FAFF">
          <th v-for="(cell,index) in (secondLine[year.length])" :key="index" :colspan="index % 2 == 0 ? (year.length == 3 ? 2 : (year.length == 2 ? 5 : 11)) : 1 "
          >{{ cell }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for=" data in tableData" :key="data" class="color-4d">
          <td scope="row" class="text-left">{{ data.title }}</td>
          <td
            :colspan="(year.length == 3 ? 2 : (year.length == 2 ? 5 : 11))"
          >{{thousandBitSeparator( data.value[0])}}</td>
          <td>{{ data.rate[0]}}</td>
          <td 
            
            v-if="year.length > 1"
            :colspan="(year.length == 3 ? 2 : (year.length == 2 ? 5 : 11))"
          >{{thousandBitSeparator( data.value[1])}}</td>
          <td  v-if="year.length > 1" >{{ data.rate[1]}}</td>
          <th
          
            v-if="year.length == 3"
            :colspan="(year.length == 3 ? 2 : (year.length == 2 ? 5 : 11))"
          >{{thousandBitSeparator( data.value[2]) }}</th>
          <td v-if="year.length == 3">{{ data.rate[2] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["tableData", "year"],

  data() {
    return {
      titleItem: ["2014-12-02", "2014-12-04", "2014-12-06"],
      secondLine: {
        3: ["金额(万元)", "占比", "金额(万元)", "占比", "金额(万元)", "占比"],
        2: ["金额(万元)", "占比", "金额(万元)", "占比"],
        1: ["金额(万元)", "占比"],
      },
      span: 2,
    };
  },
  created() {
    // console.log(this.props)
  },
  methods: {
    thousandBitSeparator: function (num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
  },
  computed: {
    s: function (index) {
      return 2;
    },
  },
};
</script>

<style>
.table thead th {
  vertical-align: middle !important;
}
</style>
