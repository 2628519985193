<template>
  <div class="table-responsive-sm" style="color:#333;font-size:16px;font-family: Arial, Helvetica, sans-serif;">
    <table class="table  table-striped">
      <thead class="bgc-be">
        <tr v-for="(tr,index) in tableData.header" :key="index" >
          <th
            :style="i=='2'||i=='3'||i=='4'||i=='5'?align:''"
            valign="middle"
            scope="row"
            v-for="(th,i) in tr"
            :key="i"
            class="color"
            :width="i==0?'120px':''||i==1?'180px':''||i==2?'120px':''||i==3?'120px':''||i==4?'120px':''||i==5?'120px':''"
          >
            {{ th.val }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr  v-for="(tr, i) in tableData.body" :key="i">
          <td 
           v-for="(td,index) in tr" 
           :key="index"
           :style="index=='2'||index=='3'||index=='4'||index=='5'?align:''"
           >
           {{ thousandBitSeparator(td.val) }}
           </td>
        </tr>
      </tbody>
    </table>
    <div
      class="noData-img"
      v-if="
        tableData == undefined || tableData == null || tableData.length <= 0
      "
    >
      <img src="@/assets/img/NewProject/nodata.svg" />
      <span>暂无数据</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["tableData"],
  data() {
    return {
      align:{
        "text-align":"center"
      },
      titleItem: ["2014-12-02", "2014-12-04", "2014-12-06"],
      secondLine: {
        3: ["金额", "/总资产", "金额", "/总资产", "金额", "/总资产"],
        2: ["金额", "/总资产", "金额", "/总资产"],
        1: ["金额", "/总资产"],
      },
      span: 2,
    };
  },
  created() {},
  methods: {
    thousandBitSeparator: function (num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
  },
  computed: {
    s: function (index) {
      return 2;
    },
  },
};
</script>

<style>
.table thead th {
  vertical-align: middle !important;
  color: #333333;
}
.color{
    color: #333 !important;
    height: 50px;
}
.table tbody td {
  color: #333;
}
.noData-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
