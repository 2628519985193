<template>
  <div style="background: #fff; padding:20px 30px 40px 30px">
    <div>
      <anaTables v-bind:tableData="getTableOne(data.data_dict)"> </anaTables>
    </div>
    <div style="padding: 15px; margin-top: 20px; background: rgb(241, 245, 255)">
      <div style="font-size: 18px;color:rgb(153, 153, 153);margin-bottom:10px">分析小结：</div>
      <div
        class="row"
        style="font-size: 16px !important;color:#333"
        v-for="(item, index) in data.desc"
        :key="index"
      >
       <span  style="font-size: 16px !important;color:#333">&nbsp &nbsp &nbsp &nbsp{{ item }}</span>
      </div>
      <div class="row">
      <div></div>
      <span style="font-size: 16px;color:#333"
        v-for="(item, index) in data.excellent_key"
        :key="index">{{item}}</span>
      </div>
      <div>
        <div class="row" style="font-size: 16px;color:#333">
          <div></div>需要引起关注的指标有： 
        </div>
       <div 
       style="font-size: 16px;color:#333"
       v-for="(item,index) in data.summary" :key="index">
        &nbsp &nbsp &nbsp{{index+1}}、{{item}}
       </div>
      </div>
    </div>
  </div>
</template>

<script>
import anaTables from "@/components/Table/AnalysisTables.vue";
export default {
  components: {
    anaTables: anaTables,
  },
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  methods: {
    getTableOne(table) {
      let header = [];
      let body = [];
      let idx = 0;
      table.forEach((item, index) => {
        if (index == 0) {
          header[0] = [];
          item.forEach((ele) => {
            header[idx].push({
              val: ele,
            });
          });
        } else {
          body[idx] = [];
          item.forEach((ele) => {
            body[idx].push({
              val: ele,
            });
          });
          idx++;
        }
      });
      return {
        header,
        body,
      };
    },
  },
};
</script>

<style lang="less" scoped>
  .row{
    align-items: center;
    div{
      width: 8px;
      height: 8px;
      background: cornflowerblue;
      border-radius: 50%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
</style>