<template>
<div>
    <div ref="eleOne" style="width: 100%; height: 346px; padding-top: 20px"></div>
</div>
  
</template>

<script>
export default {
    props:{
        data:Object
    },
  data() {
    return {};
  },
  watch:{
    data(){
      this.sysTemRuleChart();
    }
  },
  mounted() {
    this.sysTemRuleChart();
  },
  methods: {
    sysTemRuleChart() {
        let data=this.data
      var optionOne = {
        tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
        color:["#529EE2", "#F7882B","#FAD33F"],
       grid: {
        left: '2%',
        right: '2%',
        bottom: '10%',
        containLabel: true
        },
        legend: {
          x: "center",
          data: ["资产统计", "负债统计", "资产负债率"],
          textStyle:{
            fontSize:"14px"
          }
        },
        xAxis: [
          {
            type: "category",
            data: data.x,
            axisPointer: {
              type: "shadow",
            },
          }
        ],
        yAxis: [
          {
            name: "单位：万元",
            type: "value",
            axisLabel: {
              formatter: "{value}",
              color: "#999999",
            },
          },
          
          {
            type: "value",
            axisLabel: {
              formatter: "{value}%",
            },
             splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
    {
      name: '资产统计',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value;
        }
      },
      data: data.y[0].data
    },
    {
      name: '负债统计',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value;
        }
      },
      data: data.y[1].data
    },
    {
      name: '资产负债率',
      type: 'line',
      yAxisIndex: 1,
      tooltip: {
        valueFormatter: function (value) {
          return value + '%';
        }
      },
      data: data.y[2].data
    }
  ]
      };

      this.$echarts.init(this.$refs.eleOne).setOption(optionOne);
    },
  },
};
</script>

<style>
</style>