<template>
  <div>
    <!-- 概览 -->
    <OldFinancialAnalysis
      :showflag="showflag"
      :ObjectData="ObjectData"
      :ObjectData1="ObjectData1"
      ref="OldFinancialAnalysis"
    />
  </div>
</template>
<script>
import { capacity_summa } from "@/api/FinancialAnalysis.js";
import { causeWhitewash, checkpoint } from "@/api.js";
import { financialEcharts, subject_transaction } from "@/api/common";
import * as sesstion from "@/store/localStorage";
import business from "./old/business.vue";
export default {
  components: {
    OldFinancialAnalysis: require("./old/FinancialAnalysis").default,
  },
  data() {
    return {
      showflag: true,

      flag4: true,
      flag5: true,
      ObjectData: {},
      ObjectData1: {},
      param: {},
    };
  },
  created() {
    this.$VM[this.$route.name] = this;
  },
  mounted() {},
  methods: {
    search(param) {
      let checkData = {
        company_code: param.company_code,
        menu_id: "3",
        parsing_time: param.year,
        industry_type: param.industry_name
          ? param.industry_name.split("/").reverse()[0]
          : "",
        industry_number: param.industry_code,
        industry_range: 0,
        report_time: param.report_time,
        report_type: param.report_type,
      };

      checkpoint(checkData).then(({ data }) => {
        if (data.Status != 1) {
          this.$loadingHide(); //隐藏加载动画
          return this.$alert(data.Message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        } else {
          this.$loadingShow();
          const company = sesstion.getLocalData("company");
          if (company.company_type >= "3") {
            causeWhitewash({
              ...param,
              report_year: param.year,
            }).then(({ data }) => {
              let reasonReportMapping = {
                "00": "年报",
                "01": "三季报",
                "02": "半年报",
                "03": "一季报",
                "001": "一月报",
                "002": "二月报",
                "003": "三月报",
                "004": "四月报",
                "005": "五月报",
                "006": "六月报",
                "007": "七月报",
                "008": "八月报",
                "009": "九月报",
                "010": "十月报",
                "011": "十一月报",
                "012": "十二月报",
              };
              this.$store.commit(
                "setYears",
                `(${param.year}${reasonReportMapping[param.report_time]})`
              );

              this.$VM.CompanyDetail.generalEvaluation =
                data.content[0].content.horsepower.health_hp.rating;
              sessionStorage.generalEvaluation =
                data.content[0].content.horsepower.health_hp.rating;
            });
          }
          this.param = param;

          if (this.getUserRole("structural_analysis")) {
            this.financialEcharts(param); // // 结构趋势分析数据
          }
          if (this.getUserRole("account_analysis")) {
            this.subject_transaction(param); // 科目移动分析数据
          }
          this.$refs.OldFinancialAnalysis.startA(param);
        }
      });
    },

    // 结构分析数据
    financialEcharts(param) {
      param.industry_range = "0";
      param.report_year = param.year;
      financialEcharts(param).then((res) => {
        this.ObjectData = res.data;
        this.$loadingHide();
      });
    },

    // 结构分析数据
    subject_transaction(param) {
      param.industry_range = "0";
      param.report_year = param.year;
      subject_transaction(param).then((res) => {
        this.ObjectData1 = res.data;
        this.$loadingHide();
      });
    },

    // 雷达图小结
    capacity_summa(param) {
      capacity_summa(param).then(({ data }) => {
        this.$VM.ScoreOfCashFlowIndex.indicator = data.char.name;
        this.$VM.ScoreOfCashFlowIndex.value = data.char.value;

        this.$VM.ComprehensiveAbilityAnalysis.summary_list = data.desc || [];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.titles {
  padding-left: 30px;
  padding-top: 35px;
  // margin-top: 15px;
  background: #fff;

  span {
    font-weight: 550;
  }

  div {
    width: 5px;
    height: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: dodgerblue;
    align-items: center;
    margin-right: 10px;
  }
}
</style>
