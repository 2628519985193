import { render, staticRenderFns } from "./fivePower.vue?vue&type=template&id=511a6ce1&scoped=true"
import script from "./fivePower.vue?vue&type=script&lang=js"
export * from "./fivePower.vue?vue&type=script&lang=js"
import style0 from "./fivePower.vue?vue&type=style&index=0&id=511a6ce1&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511a6ce1",
  null
  
)

export default component.exports