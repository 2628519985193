<template>
  <div class="echarts">
      <div ref="echartsBig" :style="this.year[2]==undefined?'margin-left:200px':''"  class="adds"></div>

      <div v-show="this.year[2]!==undefined"  ref="echartsBigTwo" style="width: 30%; height: 400px;"></div>
    <div class="echartsLengen" >
      <div class="box">
        <div class="color1"></div>
        <div>流动资产</div>
      </div>
      <div class="box">
        <div class="color2"></div>
        <div>非流动资产</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        data:Object,
        year:Array
    },
  data() {
    return {
      arr,
      arr1,
      arr2,
    };
  },
   watch:{
    data(){
      this.echartsBig();
      this.echartsBigTwo();
      
    }
  },
  mounted() {
    this.echartsBig();
    this.echartsBigTwo();
    
  },
    computed: {
    s: function (index) {
      return 2;
    },
  },
  methods: {
     thousandBitSeparator: function (num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
    echartsBig() {
     let numList=this.data[0].value;
     let numList1=parseFloat(numList[0])
     let numList2=parseFloat(numList[1])
     let numList3=parseFloat(numList[2])
    //  console.log(numList1,numList2,numList3);
      if(numList1>numList2&&numList1>numList3){
        this.arr=["35%","65%"]
      }
      if(numList2>numList1&&numList2>numList3){
        this.arr1=["35%","65%"]
      }

      if(numList1>numList2&&numList1<numList3){
        this.arr=["30%","60%"]
      }
     if(numList1<numList2&&numList1>numList3){
        this.arr=["30%","60%"]
      }
      if(numList2<numList1&&numList2>numList3){
        this.arr1=["30%","60%"]
      }
      if(numList2>numList1&&numList2<numList3){
        this.arr1=["30%","60%"]
      }
      if(numList1<numList2&&numList1<numList3){
        this.arr=["25%","55%"]
      }
      if(numList2<numList1&&numList2<numList3){
        this.arr1=["25%","55%"]
      }

      if(numList1==numList2){
        this.arr=['25%','55%']
        this.arr1=['25%','55%']
      }

      function numFormat(num){
        var res=num.toString().replace(/\d+/, function(n){ // 先提取整数部分
            return n.replace(/(\d)(?=(\d{3})+$)/g,function($1){
                return $1+",";
              });
        })
        return res;
      }
      var optionOne = {
        color: ["#F7882B", "#529EE2"],
        title: [
          {
           text: numFormat(this.data[0].value[0]) + '（万元）',
            left: "25%",
            top: "87%",
            textAlign: "center",
            textStyle:{
                fontSize:"28px",
                color: "#3A85FD",
            }
          },
          {
            text:numFormat(this.data[0].value[1]) + '（万元）',
            left:"78%",
            top: "87%",
            textAlign: "center",
            textStyle:{
                fontSize:"28px",
                color: "#3A85FD",
            }
          },
          {
            text:this.year[0]+'年资产总计',
            left: "25%",
            top: "81%",
            textAlign: "center",
            textStyle:{
                fontSize:"18px",
                color:"#333",
                fontWeight:500
            }
          },
          {
            text:this.year[1]+'年资产总计',
            left:"78%",
            top: "81%",
            textAlign: "center",
            textStyle:{
                fontSize:"18px",
                color:"#333",
                fontWeight:500
            }
          },
       
        ],
         
        series: [
          {
            type: "pie",
            radius: this.arr,
            center:["26%", "45%"],
            // itemStyle: {
            //   borderRadius: 3,
            //   borderColor: "#fff",
            //   borderWidth: 2,
            // },
            // label: {
            //   fontSize: 12,
            //   position: "inner", //center表示文本显示位置为内部
            //   formatter:"{d}%"
            //   },
            labelLine: {
              //引导线设置
              normal: {
                show: false, //引导线不显示
              },
            },
            data:[this.data[1].value[0],this.data[2].value[0]]
          },
          {
            type: "pie",
            radius:this.arr1,
            center: ["78%", "45%"],
            // itemStyle: {
            //   borderRadius: 3,
            //   borderColor: "#fff",
            //   borderWidth: 2,
            // },
            // label: {
            //   fontSize: 12,
            //   position: "inner", //center表示文本显示位置为内部
            //   formatter:"{d}%"
            //   },
            labelLine: {
              //引导线设置
              normal: {
                show: false, //引导线不显示
              },
            },
            data:[this.data[1].value[1],this.data[2].value[1]]
          },
        ],
      };
      this.$echarts.init(this.$refs.echartsBig).setOption(optionOne);
    },
    echartsBigTwo(){
      let numList=this.data[0].value;
     let numList1=parseFloat(numList[0])
     let numList2=parseFloat(numList[1])
     let numList3=parseFloat(numList[2])
      if(numList3>numList1&&numList3>numList2){
        this.arr2=["40%","70%"]
      }
      if(numList3>numList2&&numList3<numList1){
        this.arr2=["35%","65%"]
      }
      if(numList3>numList1&&numList3<numList2){
        this.arr2=["35%","65%"]
      }
      if(numList3<numList1&&numList3<numList2){
        this.arr2=["25%","55%"]
      }
      function numFormat(num){
        var res=num.toString().replace(/\d+/, function(n){ // 先提取整数部分
            return n.replace(/(\d)(?=(\d{3})+$)/g,function($1){
                return $1+",";
              });
        })
        return res;
      }
      var option = {
        
        color: ["#F7882B", "#529EE2"],
        title: [
          {
            text: numFormat(this.data[0].value[2])+'（万元）',
            left: "50%",
            top: "87%",
            textAlign: "center",
            textStyle:{
                fontSize:"28px",
                color: "#3A85FD",
                
            }
          },
          {
            text: this.year[2]+"年资产总计",
            left: "50%",
            top: "81%",
            textAlign: "center",
            textStyle:{
                fontSize:"18px",
                color:"#333",
                fontWeight:500
            }
          },
       
        ],
          series: [
    {
      type: 'pie',
      radius: this.arr2,
      center:["50%", "45%"],
      avoidLabelOverlap: false,
      // itemStyle: {
      //   borderRadius: 3,
      //   borderColor: '#fff',
      //   borderWidth: 2
      // },
      // label: {
      //   position: 'inner',
      //   show: true,
      //   formatter: '{d}%'
      // },
      labelLine: {
        show: false
      },
      data: [this.data[1].value[2], this.data[2].value[2]]
    }
  ]
        // series: [
        //   {
        //     type: "pie",
        //     radius: this.arr2,
        //     center:["50%", "45%"],
        //     itemStyle: {
        //       borderRadius: 3,
        //       borderColor: "#fff",
        //       borderWidth: 2,
        //     },
        //     label: {
        //       fontSize: 12,
        //       position: "inner", //center表示文本显示位置为内部
        //       formatter:"{d}%"
        //     },
        //     labelLine: {
        //         show: false, //引导线不显示
        //     },
        //     data:[22,55]
        //   },
        // ],
      };
      this.$echarts.init(this.$refs.echartsBigTwo).setOption(option);
    }
  },
 
};
</script>

<style lang="less" scoped>
.echarts{
  .adds{
    width: 60%; 
    height: 400px;
  }
  position: relative;
  display: flex;
  .echartsLengen{
    .box{
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    position: absolute;
    top: 0px;
    right: 45%;
    display: flex;
    align-items: center;
    .color1{

      width: 24px;
      height: 14px;
      border-radius: 3px;
      background:#529EE2;
      margin-right: 5px;
    }
    .color2{
      width: 24px;
      height: 14px;
      border-radius: 3px;
      margin-right: 5px;
      background: #F7882B;
    }
  }

}

</style>

