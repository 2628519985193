<template>
  <div
    id="AnalysisOfSourceAndUsageDirection"
    v-if="company_type == '0' || !company_type"
  >
    <div class="kindBox">
      <div
        v-for="(kind, index) in mdoulData"
        :key="kind.name"
        :class="kindof == kind ? 'btnActive' : ''"
        @click="kindClick(kind, index)"
      >
        {{ kind }}
      </div>
    </div>

    <div
      v-if="kindof == '资产科目'"
      style="background: #fff; padding-bottom: 10px"
    >
      <div class="content_box">
        <div class="mb_jian" style="width: 100%">
          <div class="boxYe">
            <anaTable v-bind:tableData="getTableOne(ObjectData[0].content[1])">
            </anaTable>
          </div>

          <div
            style="
              background: #f1f5ff;
              padding: 15px;
              height: 175px;
              overflow-y: auto;
            "
          >
            <p style="color: #999999; margin-bottom: 0.8rem; font-size: 18px">
              分析小结：
            </p>
            <div
              class="cl"
              v-for="data in ObjectData[0].content[2]"
              :key="data"
              style="color: #333333; font-size: 16px"
            >
              <!-- <span class="TitleBoxs"></span>  -->
              {{ data }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="width: 100%; text-align: center; margin-top: 25px">
        <el-button
          v-show="flag1"
          style="width: 127px; font-size: 14px;padding:8px"
          type="primary"
          @click="lockFin1(true)"
          >查看分析小结 <span :class="`ivu-icon ivu-icon-ios-arrow-down`"></span></el-button
        >
        <el-button
          v-show="!flag1"
          style="width: 127px; font-size: 14px;padding:8px"
          type="primary"
          @click="lockFin1(false)"
          >收起 <span :class="`ivu-icon ivu-icon-ios-arrow-up`"></span></el-button
        >
      </div> -->
    </div>

    <div
      v-if="kindof == '负债科目'"
      style="margin-top: 0px; background: #fff; padding-bottom: 10px"
    >
      <div class="content_box">
        <div class="mb_jian" style="width: 100%">
          <anaTable v-bind:tableData="getTableOne(ObjectData[1].content[1])">
          </anaTable>
          <div
            style="
              background: #f1f5ff;
              padding: 15px;
              font-size: 18px;
              height: 175px;
              overflow-y: auto;
            "
          >
            <p style="color: #999999; margin-bottom: 0.8rem">分析小结：</p>
            <div
              class="cl"
              v-for="data in ObjectData[1].content[2]"
              :key="data"
              style="color: #333333; font-size: 16px"
            >
              <!-- <span class="TitleBoxs"></span>  -->
              {{ data }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="width: 100%; text-align: center; margin-top: 25px">
        <el-button
          v-show="flag2"
          style="width: 127px; font-size: 14px;padding:8px"
          type="primary"
          @click="lockFin2(true)"
          >查看分析小结 <span :class="`ivu-icon ivu-icon-ios-arrow-down`"></span></el-button
        >
        <el-button
          v-show="!flag2"
          style="width: 127px; font-size: 14px;padding:8px"
          type="primary"
          @click="lockFin2(false)"
          >收起 <span :class="`ivu-icon ivu-icon-ios-arrow-up`"></span></el-button
        >
      </div> -->
    </div>
    <div
      v-if="kindof == '利润表科目'"
      style="margin-top: 0px; background: #fff; padding-bottom: 10px"
    >
      <div class="content_box">
        <div class="mb_jian" style="width: 100%">
          <anaTable v-bind:tableData="getTableOne(ObjectData[2].content[1])">
          </anaTable>
          <div
            style="
              background: #f1f5ff;
              padding: 15px;
              font-size: 18px;
              height: 175px;
              overflow-y: auto;
            "
          >
            <p style="color: #999999; margin-bottom: 0.8rem">分析小结：</p>
            <div
              class="cl"
              style="color: #333333; font-size: 16px"
              v-for="(data, i) in ObjectData[2].content[2]"
              :key="i"
            >
              <!-- <span class="TitleBoxs"></span>  -->
              {{ i + 1 }}、
              {{ data }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="kindof == '现金流量表'"
      style="margin-top: 0px; background: #fff; padding-bottom: 10px"
    >
      <div class="content_box">
        <div class="mb_jian" style="width: 100%">
          <anaTable v-bind:tableData="getTableOne(ObjectData[3].content[1])">
          </anaTable>
          <div
            style="
              background: #f1f5ff;
              padding: 15px;
              font-size: 18px;
              height: 175px;
              overflow-y: auto;
            "
          >
            <p style="color: #999999; margin-bottom: 0.8rem">分析小结：</p>
            <div class="cl" style="color: #333333; font-size: 16px">
              <!-- <span class="TitleBoxs"></span>  -->
              {{ ObjectData[3].content[2] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as sesstion from "@/store/localStorage";
import anaTable from "@/components/Table/AnalysisTable.vue";
export default {
  props: {
    // 这里是拿到的数据用JS里使用this.ObjectData  dom里直接使用
    ObjectData: Object,
  },
  data() {
    return {
      // flag1: true,
      // flag2: true,
      // flag3: true,
      kindof: "资产科目",
      mdoulData: ["资产科目", "负债科目", "利润表科目", "现金流量表"],
      company_type: sesstion.getLocalData("company").company_type,
    };
  },
  created() {
    this.$VM.AnalysisOfSourceAndUsageDirection = this;
  },
  components: {
    CTitle: require("@/components/title/title.vue").default,
    SummaryText: require("@/components/SummaryText/index.vue").default,
    anaTable: anaTable,
    // ABar: require("./ABar").default,
  },
  methods: {
    // lockFin1(flag) {
    //   if (flag == true) {
    //     this.flag1 = false
    //   } else {
    //     this.flag1 = true
    //   }
    // },
    // lockFin2(flag) {
    //   if (flag == true) {
    //     this.flag2 = false
    //   } else {
    //     this.flag2 = true
    //   }
    // },
    // lockFin3(flag) {
    //   if (flag == true) {
    //     this.flag3 = false
    //   } else {
    //     this.flag3 = true
    //   }
    // },
    kindClick(kind, index) {
      this.kindof = kind;
    },
    getTableOne(table) {
      let header = [];
      let body = [];
      let idx = 0;
      table.forEach((item, index) => {
        if (index == 0) {
          header[0] = [];
          item.forEach((ele) => {
            header[idx].push({
              val: ele,
            });
          });
        } else {
          body[idx] = [];
          item.forEach((ele) => {
            body[idx].push({
              val: ele,
            });
          });
          idx++;
        }
      });
      return {
        header,
        body,
      };
    },
  },
};
</script>
<style lang="less" scoped>
#AnalysisOfSourceAndUsageDirection {
  .cl {
    display: flex;

    // align-items: center;
    .TitleBoxs {
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background: #1989fa;
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  //   background: #fff;
  .kindBox {
    display: flex;
    padding-left: 35px;
    // border-radius: 4px;
    overflow: hidden;

    // width: 268px;
    div {
      padding: 9px 12px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      font-size: 12px;
      cursor: pointer;
    }

    div:nth-last-child(4) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    div:nth-last-child(1) {
      border-right: 1px solid #ccc;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .btnActive {
      background: #1270ff;
      border: 1px solid #1270ff;
      opacity: 1;
      // border-radius: 4px 0px 0px 4px;
      color: #fff;
    }
  }

  .c_box {
    padding-left: 15px;
    border-bottom: 1px solid #f0f0f0;
    position: relative;

    .text {
      position: absolute;
      top: 25px;
      right: 20px;
      font-size: 16px;
      color: #333333;
    }
  }

  .content_box {
    padding: 0 35px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .boxYe {
      // height: 500px;
      overflow-y: auto;
    }

    .content {
      width: 49%;
      padding: 0 20px;
      padding-bottom: 10px;
      min-height: 462px;
      box-shadow: 0 2px 6px 0 #e9ebee;

      .content_title {
        font-size: 16px;
        color: #333333;
        padding: 16px 20px;
      }

      .unit {
        position: relative;
        z-index: 1;
        font-size: 14px;
        color: #999999;
        float: right;
        margin-top: 56px;
        margin-right: 20px;
      }

      .left {
        margin-top: 20px;

        font-size: 16px;
        color: #333333;

        div {
          margin-bottom: 10px;
        }
      }

      .right {
        width: 100%;
        margin-top: 20px;

        div {
          margin-bottom: 10px;

          .content_item {
            padding-left: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 460px) {
    .mb_jian {
      display: none;
    }
  }
}
</style>
