<template>
     <div class="header-area-dec" :class="[border && 'header-area-border', second && 'header-area-second']" > 
          <img class="header-area-dec-bg-box" src="./img/report.png" alt="">
          <div class="header-area-dec-line" ></div>
          <div class="header-area-dec-txt"  style="padding-left:24px;">
              <div>
                <img width="15" :src="second ? img2 : img1" style="font-size:0;"> <span  class="name">{{title || '财报粉饰小结：'}}</span>
              </div>
              <p style="margin-bottom: 10px;" v-for="(item, index) in judgement" :key="index">{{index+1 + '.' + item}}</p>
          </div>
        </div>
</template>
<script>

export default {
    name:  'Summary',
    props: {
        judgement: Array,
        border: Boolean,
        second: Boolean,
        title: String,
    },
    data() {
      return {
        img1: require('./img/组 348@2x.png'),
        img2: require('./img/组 348@2x_2.png'),
      }
    },
}
</script>
<style lang="less">
.header-area-border{
  border: 1px rgba(232,232,232,1) solid;
  border-left: none;
}
.header-area-dec{
  margin-top: 10px;
  width: 100%;
  position: relative;
  min-height: 90px;
   background: #fff;
   padding-top: 15px;
   padding-right: 15px;
   padding-bottom: 15px;
   .header-area-dec-bg-box{
     position: absolute;
     height: 100%;
     width: auto;
     left: 50%;
     transform: translate(-50%, -50%);
            max-height: 115px;
     top: 50%;
     >img{
       height: 100%;
       font-size: 0;

     }
   }
  >.header-area-dec-line{
    width: 4px;
    position: absolute;
    background: #F6D547;
    left: 0;
    height: 100%;
    top: 0;
   

  }
  .name{
    color:rgba(238,200,39,1);font-size:16px
  }
  .header-area-dec-txt{
    position: relative;
    z-index: 10;
  }

  
}
.header-area-second{
  .header-area-dec-line{
    background: red;
  }
  .name{
    color:red;
  }
}
@media (max-width: 460px) {
  .header-area-dec{
    font-size: 14px;
  }
}
</style>