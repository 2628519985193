<template>
  <div>
    <div class="row position-relative">
      <div style="display: flex; justify-content: space-between; width: 100%">
        <!-- <div class="kindBox"> -->
        <el-button-group>
          <el-button
            size="medium"
            v-for="(kind, index) in mdoulData"
            :key="kind.name"
            @click="kindClick(kind, index)"
            :type="kindof == kind.category ? 'primary' : ''"
            >{{ kind.category }}</el-button
          >
        </el-button-group>
        <!-- <div
            style="font-size: 14px"
            v-for="(kind, index) in mdoulData"
            :key="kind.name"
            :class="kindof == kind.category ? 'active' : ''"
            @click="kindClick(kind, index)"
          >
            {{ kind.category }}
          </div> -->
        <!-- </div> -->
      </div>
      <div style="width: 100%">
        <div>
          <div>
            <div class="mb_echar_pro" style="width: 49%; float: right">
              <div ref="echarEle" style="height: 270px"></div>
            </div>

            <div
              style="width: 49%; margin-top: 10px; color: #333; font-size: 14px"
              class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition el-table--small"
            >
              <div class="el-table__header-wrapper">
                <table style="width: 100%">
                  <thead class="has-gutter" style="color: #333">
                    <tr style="height: 50px">
                      <th
                        class="is-left is-leaf"
                        style="width: 23%; padding-left: 5px"
                      >
                        项目
                      </th>
                      <th class="is-center" style="width: 16%">{{ year }}</th>
                      <th
                        style="height: 50px; align-items: center"
                        class="row justify-content-around"
                      >
                        <span>行业优秀值</span><span>行业平均值</span
                        ><span>行业较差值</span>
                      </th>
                      <th class="is-leaf" style="width: 16%; padding-left: 5px">
                        变动率(%)
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="el-table__body-wrapper is-scrolling-none">
                <table class="el-table__body table-striped" style="width: 100%">
                  <tbody>
                    <tr
                      class="el-table__row"
                      v-for="(item, index) in dataSouces"
                      :key="item"
                      :class="index == activeIndex && 'table_row_active'"
                    >
                      <td
                        @click="setEchart(item, index)"
                        style="cursor: pointer; width: 29%"
                      >
                        <div :title="item" style="padding-left: 5px">
                          {{ item }}
                        </div>
                      </td>
                      <td
                        @click="setEchart(item, index)"
                        style="cursor: pointer; width: 16%"
                        class="is-center"
                      >
                        {{
                          dataSoucesMdole[item] && dataSoucesMdole[item].cur_val
                        }}
                      </td>
                      <td
                        @click="setEchart(item, index)"
                        style="cursor: pointer; width: 55%"
                      >
                        <div class="row justify-content-around">
                          <div style="width: 70px; text-align: center">
                            {{
                              dataSoucesMdole[item] &&
                              dataSoucesMdole[item].excel_val
                            }}
                          </div>
                          <div style="width: 70px; text-align: center">
                            {{
                              dataSoucesMdole[item] &&
                              dataSoucesMdole[item].avg_val
                            }}
                          </div>
                          <div style="width: 70px; text-align: center">
                            {{
                              dataSoucesMdole[item] &&
                              dataSoucesMdole[item].poor_val
                            }}
                          </div>
                        </div>
                        <div style="padding: 5px; margin-top: 5px">
                          <div class="slider">
                            <div class="slider1">
                              <div
                                class="sliders1"
                                :style="
                                  dataSoucesMdole[item].area === '1'
                                    ? {
                                        left:
                                          dataSoucesMdole[item].tag - 7 + '%',
                                      }
                                    : { display: 'none' }
                                "
                              ></div>
                              <div class="radio"></div>
                            </div>
                            <div class="slider2">
                              <div
                                class="sliders2"
                                :style="
                                  dataSoucesMdole[item].area === '2'
                                    ? {
                                        left:
                                          dataSoucesMdole[item].tag - 7 + '%',
                                      }
                                    : { display: 'none' }
                                "
                              ></div>
                              <div class="radio"></div>
                            </div>
                            <div class="slider3">
                              <div
                                class="sliders3"
                                :style="
                                  dataSoucesMdole[item].area === '3'
                                    ? {
                                        left:
                                          dataSoucesMdole[item].tag - 7 + '%',
                                      }
                                    : { display: 'none' }
                                "
                              ></div>
                              <div class="radio"></div>
                            </div>
                            <div class="slider4">
                              <div
                                class="sliders4"
                                :style="
                                  dataSoucesMdole[item].area === '4'
                                    ? {
                                        left:
                                          dataSoucesMdole[item].tag - 7 + '%',
                                      }
                                    : { display: 'none' }
                                "
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        class="is-center"
                        style="
                          cursor: pointer;
                          width: 130px;
                          padding-left: 10px;
                        "
                      >
                        <template class="differUp">
                          <span
                            style="width: 50px"
                            v-if="dataSoucesMdole[item].differ > 0"
                            >+{{
                              dataSoucesMdole[item] &&
                              dataSoucesMdole[item].differ
                            }}</span
                          >
                          <span style="width: 50px" v-else>{{
                            dataSoucesMdole[item] &&
                            dataSoucesMdole[item].differ
                          }}</span>
                          <img
                            class="upimg"
                            v-if="dataSoucesMdole[item].differ > 0"
                            src="@/assets/img/arrow/arrow-up-green.png"
                            alt=""
                            srcset=""
                          />
                          <img
                            class="upimg"
                            v-if="dataSoucesMdole[item].differ < 0"
                            src="@/assets/img/arrow/up-a-red.png"
                            alt=""
                            srcset=""
                          />
                          <span
                            v-if="dataSoucesMdole[item].differ == '--'"
                          ></span>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="!dataSouces.length">
                      <td class="is-center" colspan="5">暂无数据</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as sesstion from "@/store/localStorage";
import { stdval, cate, causeIndexCalc, causeIndexCate } from "@/api.js";
import { data1, data3 } from "./kindof.js";
import { indicators } from "@/api/common.js";
export default {
  name: "fivePower",
  dec: "五大能力",
  components: {
    // EchartLine: require("@/components/line/index").default,
  },
  data() {
    return {
      value: 2,
      post: "25px",
      mdoulData: data3,
      form_data: {},
      dataSouces: [],
      dataSoucesMdole: {},
      kindof: "盈利能力",
      importantActive: 0,
      year: "",
      form_data: {},
      echarts_data: {},
      series: [],
      date: [],
      activeIndex: 0,
      regions: "全国", //地区值，默认全国
      regions_arr: [], //地区列表，
    };
  },
  created() {
    this.mdoulData = data3;
  },
  mounted() {},
  methods: {
    getData(five_param) {
      sesstion.setLocalData("fivePowerParam", five_param);
      stdval({ ...five_param }).then(({ data }) => {
        if (data.Status != 1) return this.$Message.warning(data.msg);

        for (const key in data.result.form_data) {
          if (data.result.form_data.hasOwnProperty(key)) {
            this.year = key;
            const objData = data.result.form_data[key];

            for (let i in objData) {
              if (i == "净资产收益率（%）") {
                objData["年化后净资产收益率（%）"] =
                  objData["净资产收益率（%）"];
                delete objData["净资产收益率（%）"];
              }
              if (i == "总资产报酬率（%）") {
                objData["年化后总资产报酬率（%）"] =
                  objData["总资产报酬率（%）"];
                delete objData["总资产报酬率（%）"];
              }
              if (i == "资本收益率（%）") {
                objData["年化后资本收益率（%）"] = objData["资本收益率（%）"];
                delete objData["资本收益率（%）"];
              }
              if (i == "存货周转率（次）") {
                objData["年化后存货周转率（次）"] = objData["存货周转率（次）"];
                delete objData["存货周转率（次）"];
              }
              if (i == "流动资产周转率（次）") {
                objData["年化后流动资产周转率（次）"] =
                  objData["流动资产周转率（次）"];
                delete objData["流动资产周转率（次）"];
              }
              if (i == "应收账款周转率（次）") {
                objData["年化后应收账款周转率（次）"] =
                  objData["应收账款周转率（次）"];
                delete objData["应收账款周转率（次）"];
              }
              if (i == "总资产周转率（次）") {
                objData["年化后总资产周转率（次）"] =
                  objData["总资产周转率（次）"];
                delete objData["总资产周转率（次）"];
              }
              if (i == "现金流动负债比率（%）") {
                objData["年化后现金流动负债比率（%）"] =
                  objData["现金流动负债比率（%）"];
                delete objData["现金流动负债比率（%）"];
              }
            }
            this.dataSoucesMdole = objData;
          }
        }
        const objDatas = data.result.echarts_data.val;

        for (let i in objDatas) {
          if (i == "净资产收益率（%）") {
            objDatas["年化后净资产收益率（%）"] = objDatas["净资产收益率（%）"];
            delete objDatas["净资产收益率（%）"];
          }
          if (i == "总资产报酬率（%）") {
            objDatas["年化后总资产报酬率（%）"] = objDatas["总资产报酬率（%）"];
            delete objDatas["总资产报酬率（%）"];
          }
          if (i == "资本收益率（%）") {
            objDatas["年化后资本收益率（%）"] = objDatas["资本收益率（%）"];
            delete objDatas["资本收益率（%）"];
          }
          if (i == "存货周转率（次）") {
            objDatas["年化后存货周转率（次）"] = objDatas["存货周转率（次）"];
            delete objDatas["存货周转率（次）"];
          }
          if (i == "流动资产周转率（次）") {
            objDatas["年化后流动资产周转率（次）"] =
              objDatas["流动资产周转率（次）"];
            delete objDatas["流动资产周转率（次）"];
          }
          if (i == "应收账款周转率（次）") {
            objDatas["年化后应收账款周转率（次）"] =
              objDatas["应收账款周转率（次）"];
            delete objDatas["应收账款周转率（次）"];
          }
          if (i == "总资产周转率（次）") {
            objDatas["年化后总资产周转率（次）"] =
              objDatas["总资产周转率（次）"];
            delete objDatas["总资产周转率（次）"];
          }
          if (i == "现金流动负债比率（%）") {
            objDatas["年化后现金流动负债比率（%）"] =
              objDatas["现金流动负债比率（%）"];
            delete objDatas["现金流动负债比率（%）"];
          }
        }
        this.echarts_data.date = data.result.echarts_data.date;
        this.echarts_data.val = objDatas;

        this.setArr();
      });
    },
    setArr() {
      var dataSouces = [];
      for (let index = 0; index < this.mdoulData.length; index++) {
        const item = this.mdoulData[index];
        if (item.category == this.kindof) {
          dataSouces = item.res.map((item) => item.title);
          break;
        }
      }
      this.dataSouces = dataSouces;
      this.setEchart(dataSouces[0], 0);
    },
    setEchart(name, index) {
      this.activeIndex = index;
      let date = [];
      // let company_type = sesstion.getLocalData("company").company_type;
      // console.log(company_type);
      // if (company_type >= "3") {
      //   date = [...this.echarts_data.data];
      // } else {
      //   date = [...this.echarts_data.date];
      // }
      date = [...this.echarts_data.date];
      var than = JSON.parse(JSON.stringify(this.echarts_data.val[name]));
      // var a = "yoy是同比，qoq是环比",
      var colorArr = ["#1989FA", "#F7882B", "#10CAEB"];
      var obj = {
        avg: "行业平均值",
        yoy: "同比增长率(%)",
        qoq: "环比增长率(%)",
        cur: name,
      };
      // var arr = ["cur", "yoy", "qoq", "avg"];
      var arr = ["cur", "avg"];
      var series = [];

      arr.map((key, index) => {
        const item = than[key];
        if (!item) return false;
        series.push({
          name: obj[key],
          data: item.reverse(),
          // type: key == "cur" ? "bar" : "line",
          type: "line",
          barWidth: 30,
          itemStyle: {
            normal: {
              // barBorderRadius: [20, 20, 0, 0],
              color: colorArr[index],
            },
          },
          yAxisIndex: 0,
          // yAxisIndex: index && 1,
        });
      });
      for (const key in than) {
        if (than.hasOwnProperty(key)) {
          const item = than[key];
        }
      }
      this.series = series;
      this.date = date.reverse();

      var option = {
        background: [],
        tooltip: {
          formatter: "{a0}: {c0}",
        },
        // color: ["#1989FA", "#10CAEB", "#FEC400"],
        legend: {},
        xAxis: {
          type: "category",
          data: this.date,
          axisLine: {
            show: true,
            alignWithLabel: false,
            lineStyle: {
              color: "#F0F0F0",
              width: "4",
            },
          },
          axisLabel: {
            color: "#999999",
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "value",
            min: "dataMin",
            max: "dataMax",
            axisLine: {
              show: false,
              alignWithLabel: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: " #F0F0F0",
              },
            },
          },
          // {
          //   type: "value",
          //   splitLine: {
          //     show: false,
          //   },
          //   axisLine: {
          //     show: false,
          //     alignWithLabel: false,
          //   },
          //   axisTick: {
          //     show: false,
          //   },
          // },
        ],

        series: this.series,
      };

      this.$echarts.init(this.$refs.echarEle).setOption(option);
    },
    setColor(index) {
      this.activeIndex = index;
    },
    kindClick(kind, index) {
      this.kindof = kind.category;

      this.setArr();
      this.setColor(0);
    },
  },
};
</script>
<style lang="less" scoped>
.mb_table_box {
  width: 49%;
}

.mb_table_wu {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.differUp {
  // width: 10px;
}

.upimg {
  width: 20px;
  height: 12px;
}

.kindBox {
  display: flex;

  div {
    padding: 9px 12px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    font-size: 12px;

    cursor: pointer;
  }

  div:nth-last-child(1) {
    border-right: 1px solid #ccc;
  }

  .active {
    background: #1270ff;
    border: 1px solid #1270ff;
    opacity: 1;
    // border-radius: 4px 0px 0px 4px;
    color: #fff;
  }
}

.kindBox1 {
  > div {
    padding: 5px 34px;
  }
}

.fff {
  background: #fff;
}

.table_row_active {
  color: #1270ff;
}

@media (max-width: 460px) {
  .kindBox {
    display: flex;

    div {
      padding: 9px 12px;
    }
  }

  .mb_table_box {
    width: 100%;
    font-size: 13px;
  }

  .mb_echar_pro {
    display: none;
  }

  .mb_table_wu {
    width: 100px;
    white-space: pre-wrap;
  }
}
</style>
