export const data1 = [
  {
    category: "盈利能力",
    res: [
      {
        title: "成本费用占主营业务收入比重（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 78.75, excel_val: 58.6, avg: 88, poor_val: 110.4 },
          },
          {
            year: "2017",
            data: {
              cur_val: 81.11,
              excel_val: 59.4,
              avg: 88.8,
              poor_val: 111.2,
            },
          },
          {
            year: "2016",
            data: { cur_val: 85.36, excel_val: 65.6, avg: 95, poor_val: 117.4 },
          },
        ],
      },
      {
        title: "净资产收益率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 23.34, excel_val: 12.7, avg: 6.7, poor_val: -2.6 },
          },
          {
            year: "2017",
            data: { cur_val: 21.36, excel_val: 12.9, avg: 6.9, poor_val: -2.5 },
          },
          {
            year: "2016",
            data: { cur_val: 19.03, excel_val: 11, avg: 5, poor_val: -4.4 },
          },
        ],
      },
      {
        title: "总资产报酬率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 5.01, excel_val: 7.9, avg: 3.7, poor_val: -1 },
          },
          {
            year: "2017",
            data: { cur_val: 5.12, excel_val: 7.7, avg: 3.5, poor_val: -1.2 },
          },
          {
            year: "2016",
            data: { cur_val: 5.44, excel_val: 6.4, avg: 2.2, poor_val: -2.5 },
          },
        ],
      },
      {
        title: "盈余现金保障倍数",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 0.68, excel_val: 13, avg: 1, poor_val: -4.4 },
          },
          {
            year: "2017",
            data: { cur_val: 2.21, excel_val: 12.7, avg: 0.7, poor_val: -4.7 },
          },
          {
            year: "2016",
            data: { cur_val: 1.4, excel_val: 12.6, avg: 0.6, poor_val: -4.8 },
          },
        ],
      },
      {
        title: "资本收益率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 256.54, excel_val: 14.3, avg: 7, poor_val: -10.7 },
          },
          {
            year: "2017",
            data: {
              cur_val: 192.41,
              excel_val: 13.1,
              avg: 7.1,
              poor_val: -11.9,
            },
          },
          {
            year: "2016",
            data: { cur_val: 147.14, excel_val: 11.9, avg: 8, poor_val: -13.1 },
          },
        ],
      },
      {
        title: "销售（营业）利润率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 29.7, excel_val: 29.4, avg: 19, poor_val: 4 },
          },
          {
            year: "2017",
            data: { cur_val: 25.98, excel_val: 27.4, avg: 17, poor_val: 2 },
          },
          {
            year: "2016",
            data: { cur_val: 20.27, excel_val: 20.4, avg: 10, poor_val: -5 },
          },
        ],
      },
      {
        title: "成本费用利润率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 28.78, excel_val: 22.5, avg: 12, poor_val: -4 },
          },
          {
            year: "2017",
            data: {
              cur_val: 25.96,
              excel_val: 25.2,
              avg: 14.7,
              poor_val: -1.3,
            },
          },
          {
            year: "2016",
            data: { cur_val: 19.12, excel_val: 20.5, avg: 10, poor_val: -6 },
          },
        ],
      },
    ],
  },
  {
    category: "营运能力",
    res: [
      {
        title: "存货周转率（次）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 0.28, excel_val: 2.9, avg: 0.5, poor_val: 0.1 },
          },
          {
            year: "2017",
            data: { cur_val: 0.3, excel_val: 2.6, avg: 0.4, poor_val: 0.1 },
          },
          {
            year: "2016",
            data: { cur_val: 0.41, excel_val: 2.7, avg: 0.5, poor_val: 0.3 },
          },
        ],
      },
      {
        title: "流动资产周转率（次）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 0.26, excel_val: 0.8, avg: 0.3, poor_val: 0.1 },
          },
          {
            year: "2017",
            data: { cur_val: 0.28, excel_val: 0.6, avg: 0.3, poor_val: 0.1 },
          },
          {
            year: "2016",
            data: { cur_val: 0.38, excel_val: 0.7, avg: 0.4, poor_val: 0.2 },
          },
        ],
      },
      {
        title: "应收账款周转率（次）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 197.21, excel_val: 28.2, avg: 6, poor_val: 3.2 },
          },
          {
            year: "2017",
            data: { cur_val: 138.48, excel_val: 27.4, avg: 5.2, poor_val: 2.3 },
          },
          {
            year: "2016",
            data: { cur_val: 104.88, excel_val: 27.3, avg: 5, poor_val: 2.1 },
          },
        ],
      },
      {
        title: "总资产周转率（次）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 0.22, excel_val: 0.7, avg: 0.3, poor_val: 0.1 },
          },
          {
            year: "2017",
            data: {
              cur_val: 0.24,
              excel_val: "缺少标准",
              avg: "缺少标准",
              poor_val: "缺少标准",
            },
          },
          {
            year: "2016",
            data: { cur_val: 0.33, excel_val: 0.6, avg: 0.3, poor_val: 0.1 },
          },
        ],
      },
    ],
  },
  {
    category: "发展能力",
    res: [
      {
        title: "资本积累率（%）",
        alldata: [
          {
            year: "2018",
            data: {
              cur_val: 26.22,
              excel_val: 35.6,
              avg: 8.4,
              poor_val: -17.3,
            },
          },
          {
            year: "2017",
            data: {
              cur_val: 15.46,
              excel_val: 42.1,
              avg: 14.9,
              poor_val: -4.6,
            },
          },
          {
            year: "2016",
            data: { cur_val: 18.61, excel_val: 39.2, avg: 12, poor_val: 4.7 },
          },
        ],
      },
      {
        title: "销售（营业）利润增长率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 40.1, excel_val: 25.1, avg: 7, poor_val: -13.7 },
          },
          {
            year: "2017",
            data: {
              cur_val: 29.41,
              excel_val: 11.6,
              avg: -6.7,
              poor_val: -27.5,
            },
          },
          {
            year: "2016",
            data: { cur_val: 23.69, excel_val: 15.6, avg: -3, poor_val: -24.1 },
          },
        ],
      },
      {
        title: "总资产增长率（%）",
        alldata: [
          {
            year: "2018",
            data: {
              cur_val: 31.17,
              excel_val: 32.6,
              avg: 13.5,
              poor_val: -10.2,
            },
          },
          {
            year: "2017",
            data: {
              cur_val: 40.29,
              excel_val: 26.3,
              avg: 10.6,
              poor_val: -13.1,
            },
          },
          {
            year: "2016",
            data: {
              cur_val: 35.89,
              excel_val: 32.7,
              avg: 13.5,
              poor_val: -10.2,
            },
          },
        ],
      },
      {
        title: "销售（营业）增长率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 22.55, excel_val: 30.7, avg: 12, poor_val: -8.2 },
          },
          {
            year: "2017",
            data: { cur_val: 1.01, excel_val: 17.9, avg: -0.8, poor_val: -21 },
          },
          {
            year: "2016",
            data: { cur_val: 22.98, excel_val: 28.7, avg: 10, poor_val: -10.2 },
          },
        ],
      },
    ],
  },
  {
    category: "短期偿债能力",
    res: [
      {
        title: "速动比率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 48.56, excel_val: 145.5, avg: 74, poor_val: 38.7 },
          },
          {
            year: "2017",
            data: {
              cur_val: 49.5,
              excel_val: 138.4,
              avg: 66.9,
              poor_val: 31.6,
            },
          },
          {
            year: "2016",
            data: { cur_val: 43.78, excel_val: 139.6, avg: 68, poor_val: 32.6 },
          },
        ],
      },
      {
        title: "现金流动负债比率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 3, excel_val: 16.2, avg: 1.5, poor_val: -15.8 },
          },
          {
            year: "2017",
            data: { cur_val: 9.72, excel_val: 17.2, avg: 2.5, poor_val: -14.8 },
          },
          {
            year: "2016",
            data: { cur_val: 6.82, excel_val: 15.8, avg: 1.1, poor_val: -16.2 },
          },
        ],
      },
    ],
  },
  {
    category: "长期偿债能力",
    res: [
      {
        title: "资产负债率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 84.59, excel_val: 50, avg: 70, poor_val: 90 },
          },
          {
            year: "2017",
            data: { cur_val: 83.98, excel_val: 50, avg: 70, poor_val: 90 },
          },
          {
            year: "2016",
            data: { cur_val: 80.54, excel_val: 50, avg: 70, poor_val: 90 },
          },
        ],
      },
      {
        title: "已获利息倍数",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 12.25, excel_val: 5.5, avg: 2.7, poor_val: -2.1 },
          },
          {
            year: "2017",
            data: { cur_val: 25.64, excel_val: 5.6, avg: 2.8, poor_val: -2 },
          },
          {
            year: "2016",
            data: { cur_val: 25.66, excel_val: 5.5, avg: 2.7, poor_val: -2.1 },
          },
        ],
      },
      {
        title: "带息负债比率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 19.12, excel_val: 31.2, avg: 50, poor_val: 90.7 },
          },
          {
            year: "2017",
            data: {
              cur_val: 19.58,
              excel_val: 27.6,
              avg: 46.2,
              poor_val: 86.8,
            },
          },
          {
            year: "2016",
            data: { cur_val: 19.32, excel_val: 25.6, avg: 44, poor_val: 84.3 },
          },
        ],
      },
    ],
  },
];
export const data2 = [
  {
    category: "盈利能力",
    res: [
      {
        title: "收支结余率（%）",
      },
      {
        title: "净资产收益率（%）",
      }
    ],
  },
  {
    category: "营运能力",
    res: [
      {
        title: "固定资产周转率（次）",
      },
      {
        title: "流动资产周转率（次）",
      }
    ],
  },
  {
    category: "发展能力",
    res: [
      {
        title: "收入增长率（%）",
      },
      {
        title: "事业收入增长率（%）",
      }
    ],
  },
  {
    category: "短期偿债能力",
    res: [
      {
        title: "负债收入比率（%）",
      }
    ],
  },
  {
    category: "长期偿债能力",
    res: [
      {
        title: "资产负债率（%）",
      },
      {
        title: "经费自给率（%）",
      }
    ],
  },
];
export const data3 = [
  {
    category: "盈利能力",
    res: [
      {
        title: "成本费用占主营业务收入比重（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 78.75, excel_val: 58.6, avg: 88, poor_val: 110.4 },
          },
          {
            year: "2017",
            data: { cur_val: 81.11, excel_val: 59.4, avg: 88.8, poor_val: 111.2, },
          },
          {
            year: "2016",
            data: { cur_val: 85.36, excel_val: 65.6, avg: 95, poor_val: 117.4 },
          },
        ],
      },
      {
        title: "年化后净资产收益率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 23.34, excel_val: 12.7, avg: 6.7, poor_val: -2.6 },
          },
          {
            year: "2017",
            data: { cur_val: 21.36, excel_val: 12.9, avg: 6.9, poor_val: -2.5 },
          },
          {
            year: "2016",
            data: { cur_val: 19.03, excel_val: 11, avg: 5, poor_val: -4.4 },
          },
        ],
      },
      {
        title: "年化后总资产报酬率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 5.01, excel_val: 7.9, avg: 3.7, poor_val: -1 },
          },
          {
            year: "2017",
            data: { cur_val: 5.12, excel_val: 7.7, avg: 3.5, poor_val: -1.2 },
          },
          {
            year: "2016",
            data: { cur_val: 5.44, excel_val: 6.4, avg: 2.2, poor_val: -2.5 },
          },
        ],
      },
      {
        title: "盈余现金保障倍数",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 0.68, excel_val: 13, avg: 1, poor_val: -4.4 },
          },
          {
            year: "2017",
            data: { cur_val: 2.21, excel_val: 12.7, avg: 0.7, poor_val: -4.7 },
          },
          {
            year: "2016",
            data: { cur_val: 1.4, excel_val: 12.6, avg: 0.6, poor_val: -4.8 },
          },
        ],
      },
      {
        title: "年化后资本收益率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 256.54, excel_val: 14.3, avg: 7, poor_val: -10.7 },
          },
          {
            year: "2017",
            data: { cur_val: 192.41, excel_val: 13.1, avg: 7.1, poor_val: -11.9, },
          },
          {
            year: "2016",
            data: { cur_val: 147.14, excel_val: 11.9, avg: 8, poor_val: -13.1 },
          },
        ],
      },
      {
        title: "销售（营业）利润率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 29.7, excel_val: 29.4, avg: 19, poor_val: 4 },
          },
          {
            year: "2017",
            data: { cur_val: 25.98, excel_val: 27.4, avg: 17, poor_val: 2 },
          },
          {
            year: "2016",
            data: { cur_val: 20.27, excel_val: 20.4, avg: 10, poor_val: -5 },
          },
        ],
      },
      {
        title: "成本费用利润率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 28.78, excel_val: 22.5, avg: 12, poor_val: -4 },
          },
          {
            year: "2017",
            data: { cur_val: 25.96, excel_val: 25.2, avg: 14.7, poor_val: -1.3, },
          },
          {
            year: "2016",
            data: { cur_val: 19.12, excel_val: 20.5, avg: 10, poor_val: -6 },
          },
        ],
      },
    ],
  },
  {
    category: "营运能力",
    res: [
      {
        title: "年化后存货周转率（次）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 0.28, excel_val: 2.9, avg: 0.5, poor_val: 0.1 },
          },
          {
            year: "2017",
            data: { cur_val: 0.3, excel_val: 2.6, avg: 0.4, poor_val: 0.1 },
          },
          {
            year: "2016",
            data: { cur_val: 0.41, excel_val: 2.7, avg: 0.5, poor_val: 0.3 },
          },
        ],
      },
      {
        title: "年化后流动资产周转率（次）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 0.26, excel_val: 0.8, avg: 0.3, poor_val: 0.1 },
          },
          {
            year: "2017",
            data: { cur_val: 0.28, excel_val: 0.6, avg: 0.3, poor_val: 0.1 },
          },
          {
            year: "2016",
            data: { cur_val: 0.38, excel_val: 0.7, avg: 0.4, poor_val: 0.2 },
          },
        ],
      },
      {
        title: "年化后应收账款周转率（次）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 197.21, excel_val: 28.2, avg: 6, poor_val: 3.2 },
          },
          {
            year: "2017",
            data: { cur_val: 138.48, excel_val: 27.4, avg: 5.2, poor_val: 2.3 },
          },
          {
            year: "2016",
            data: { cur_val: 104.88, excel_val: 27.3, avg: 5, poor_val: 2.1 },
          },
        ],
      },
      {
        title: "年化后总资产周转率（次）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 0.22, excel_val: 0.7, avg: 0.3, poor_val: 0.1 },
          },
          {
            year: "2017",
            data: { cur_val: 0.24, excel_val: "缺少标准", avg: "缺少标准", poor_val: "缺少标准", },
          },
          {
            year: "2016",
            data: { cur_val: 0.33, excel_val: 0.6, avg: 0.3, poor_val: 0.1 },
          },
        ],
      },
    ],
  },
  {
    category: "发展能力",
    res: [
      {
        title: "资本积累率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 26.22, excel_val: 35.6, avg: 8.4, poor_val: -17.3, },
          },
          {
            year: "2017",
            data: { cur_val: 15.46, excel_val: 42.1, avg: 14.9, poor_val: -4.6, },
          },
          {
            year: "2016",
            data: { cur_val: 18.61, excel_val: 39.2, avg: 12, poor_val: 4.7 },
          },
        ],
      },
      {
        title: "销售（营业）利润增长率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 40.1, excel_val: 25.1, avg: 7, poor_val: -13.7 },
          },
          {
            year: "2017",
            data: { cur_val: 29.41, excel_val: 11.6, avg: -6.7, poor_val: -27.5, },
          },
          {
            year: "2016",
            data: { cur_val: 23.69, excel_val: 15.6, avg: -3, poor_val: -24.1 },
          },
        ],
      },
      {
        title: "总资产增长率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 31.17, excel_val: 32.6, avg: 13.5, poor_val: -10.2, },
          },
          {
            year: "2017",
            data: { cur_val: 40.29, excel_val: 26.3, avg: 10.6, poor_val: -13.1, },
          },
          {
            year: "2016",
            data: { cur_val: 35.89, excel_val: 32.7, avg: 13.5, poor_val: -10.2, },
          },
        ],
      },
      {
        title: "销售（营业）增长率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 22.55, excel_val: 30.7, avg: 12, poor_val: -8.2 },
          },
          {
            year: "2017",
            data: { cur_val: 1.01, excel_val: 17.9, avg: -0.8, poor_val: -21 },
          },
          {
            year: "2016",
            data: { cur_val: 22.98, excel_val: 28.7, avg: 10, poor_val: -10.2 },
          },
        ],
      },
    ],
  },
  {
    category: "短期偿债能力",
    res: [
      {
        title: "速动比率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 48.56, excel_val: 145.5, avg: 74, poor_val: 38.7 },
          },
          {
            year: "2017",
            data: {
              cur_val: 49.5,
              excel_val: 138.4,
              avg: 66.9,
              poor_val: 31.6,
            },
          },
          {
            year: "2016",
            data: { cur_val: 43.78, excel_val: 139.6, avg: 68, poor_val: 32.6 },
          },
        ],
      },
      {
        title: "年化后现金流动负债比率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 3, excel_val: 16.2, avg: 1.5, poor_val: -15.8 },
          },
          {
            year: "2017",
            data: { cur_val: 9.72, excel_val: 17.2, avg: 2.5, poor_val: -14.8 },
          },
          {
            year: "2016",
            data: { cur_val: 6.82, excel_val: 15.8, avg: 1.1, poor_val: -16.2 },
          },
        ],
      },
    ],
  },
  {
    category: "长期偿债能力",
    res: [
      {
        title: "资产负债率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 84.59, excel_val: 50, avg: 70, poor_val: 90 },
          },
          {
            year: "2017",
            data: { cur_val: 83.98, excel_val: 50, avg: 70, poor_val: 90 },
          },
          {
            year: "2016",
            data: { cur_val: 80.54, excel_val: 50, avg: 70, poor_val: 90 },
          },
        ],
      },
      {
        title: "已获利息倍数",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 12.25, excel_val: 5.5, avg: 2.7, poor_val: -2.1 },
          },
          {
            year: "2017",
            data: { cur_val: 25.64, excel_val: 5.6, avg: 2.8, poor_val: -2 },
          },
          {
            year: "2016",
            data: { cur_val: 25.66, excel_val: 5.5, avg: 2.7, poor_val: -2.1 },
          },
        ],
      },
      {
        title: "带息负债比率（%）",
        alldata: [
          {
            year: "2018",
            data: { cur_val: 19.12, excel_val: 31.2, avg: 50, poor_val: 90.7 },
          },
          {
            year: "2017",
            data: { cur_val: 19.58, excel_val: 27.6, avg: 46.2, poor_val: 86.8, },
          },
          {
            year: "2016",
            data: { cur_val: 19.32, excel_val: 25.6, avg: 44, poor_val: 84.3 },
          },
        ],
      },
    ],
  },
];
